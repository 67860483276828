import dev_comm from "assets/images/developpement_comunautaire.jpg";
import entrep from "assets/images/enjeune.jpg";
import sante from "assets/images/nutrition.jpg";
import alphab from "assets/images/alphabetisation.jpg";
import action_social from "assets/images/action_sociale.jpg";
import droit_de_lhomme from "assets/images/droit_de_homme.jpg";
import paix_cohesion from "assets/images/paix_et_cohesion sociale.jpg";

import dev_durable from "assets/images/environnement.jpg";
const data_action = [{
    id:1,
    image:dev_comm,
    titre:'Développement communautaire et social',
    description:`Dans la mise en œuvre des projets de développement communautaire et social, EICF mène des actions de :
    `,
    liste:["sensibilisation et de mobilisation communautaires","sensibilisation de masse et de proximité",
    "mobilisation des acteurs clefs et des parties prenantes","assemblées communautaires","sensibilisation sur les approches participatives et inclusives"]
},
{
    id:2,
    image:entrep,
    titre:'Entreprenariat des jeunes et des femmes ',
    description:"Dans le domaine de l’entrepreneuriat des jeunes et des femmes, EICF mène des actions destinées à l’autonomisation économique et financière des jeunes et des femmes. Ces actions portent sur :",
    liste:["la réintégration socioéconomique des personnes vulnérables","l’autonomisation économique et financière des jeunes et des femmes",
    "la promotion de l’entreprenariat des jeunes et des femmes à travers la formation professionnelle et la création de micro-entreprises",
    "la création et la gestion d’Activités Génératrices de Revenu (AGR) ","l’inclusion financière des populations rurales à travers la mise en place des AVEC (Associations Villageoises d’Epargne et de Crédit) pour l’auto-financement de micro-projets", 
"le suivi post-installation des bénéficiaires entrepreneurs"]
},
{
    id:3,
    image:sante,
    titre:"Santé et Nutrition ",
    description:'Dans le domaine de la santé publique, EICF mène des actions destinées à préserver la bonne santé et le bon état nutritionnel de la population en général et plus spécifiquement des enfants de 0 à 5ans. Ces actions portent sur des campagnes de sensibilisation sur :',
    liste:[
        "la santé maternelle et planification familiale",
        "la santé Sexuelle et Reproductive (SSR)",
        "le dépistage des MST en particulier sur le VIH",
        "le dépistage du Diabète et de l’hypertension",
        "le dépistage des Maladies Tropicales Négligées",
        "les bonnes pratiques nutritionnelles et d’hygiène",
        "le dépistage des enfants ; des femmes enceintes et des femmes allaitantes malnutris (MAS et MAM)",
        "la réalisation des activités de suivi, promotion de la croissance.",
        "la prise en charge médicale et communautaire des malnutris dépistés.",
        "la prise en charge et le suivi des malades dépistées",
        "la réalisation des activités de démonstration culinaire."
    ]
},
{
    id:4,
    image:alphab,
    titre:'Education-Formation-Alphabétisation ',
    description:'EICF sensibilise sur :',
    liste:[
        "le maintien de la jeune fille à l’école",
        "la scolarisation de la jeune fille en milieu rural",
        "zéro grossesse en milieu scolaire",
        "les VBG en milieu scolaire",
        "la mise en place des classes passerelles",
        "le Développement de la petite enfance (DPE)",
        "la scolarisation des enfants en âge d’aller à l’école",
        "les dangers liés au travail des enfants en milieu rural et urbain",
        "La scolarisation de la jeune fille en milieu rural et urbain",
        "La scolarisation des enfants en âge d’aller à l’école",
        "Les dangers du travail des enfants en milieu rural et urbain",
        "L’alphabétisation en milieu rural"
    ]
},
// {
//     id:5,
//     image:action_social,
//     titre:"Actions sociales et humanitaire",
//     description:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil illo modi laboriosam ipsam, qui, cumque omnis necessitatibus voluptate error doloremque quis non? Debitis tenetur sed quam consequatur voluptate dolorem autem!',
//     liste:[]
// },
{
    id:6,
    image:droit_de_lhomme,
    titre:"Genre et droits humains ",
    description:'Sur les thématiques du Genre et des Droits de l’homme, EICF mène des actions à travers des campagnes de sensibilisation sur :',
    liste: [
        "les questions de genre et de l’autonomisation de la femme",
        "les droits et devoirs de la femme en milieu rural",
        "le droit à l’éducation de la jeune fille",
        "les conséquences du mariage forcé de la jeune fille",
        "les toutes les formes de VBG et leurs conséquences sur la jeune fille",
        "la prise en compte du GENRE dans toute prise de décision locale ou nationale à travers des plaidoyers",
        "la Santé Sexuelle et de la Reproduction (SSR) et sur le Planning Familial avec l’implication des hommes à travers les Club Des Maris (DM) ou Des Futurs Maris (CDFM)"
    ]
},
{
    id:7,
    image:paix_cohesion,
    titre:"Paix et cohésion sociale",
    description:'L’ONG mène des actions de sensibilisation sur :',
    liste:[
        "la cohésion sociale, la cohabitation pacifique entre les peuples et les communautés",
        "la non-prolifération des Armes Légères et Petits Calibres (ALPC)",
        "des élections apaisées et sans violence en périodes électorales",
        "les questions de genre et de l’autonomisation de la femme",
        "l’installation des comités de paix dans les communautés rurales"
    ]
},
{
    id:8,
    image:dev_durable,
    titre:"Environnement et développement durable",
    description:'L’ONG fait la promotion des actions en faveur de la protection de l’environnement à travers :',
    liste: [
        "des activités sur l’hygiène publique et la salubrité en milieu rural et urbain",
        "des activités sur l’entretien des pistes rurales (désherbage, curage des caniveau…)",
        "des activités sur la protection de la forêt par le reboisement par des actions contre la déforestation et l’émission de gaz à effets de serres",
        "la promotion d'un modèle de développement durable"
    ]
},

]

export default data_action;