import rapport_1 from "assets/images/rapport_annuel.pdf"
import rapport_2 from "assets/images/rapport_swedd.pdf"
import rapport_3 from "assets/images/rapport_pmndpe.pdf"
import rapport_4 from "assets/images/rapport1.pdf"
const data_rapport = [{
    id:1,
    titre:"Rapport annuel EICF 2022",
    pdf:rapport_1
},{
    id:2,
    titre:"Rapport projet SWEDD EDUCATION",
    pdf:rapport_2
},{
    id:3,
    titre:"Rapport projet PMNDPE",
    pdf:rapport_3
},
{
    id:4,
    titre:"Rapport projet T2 FBP DPT KOUNAHIRI",
    pdf:rapport_4
}



]

export default data_rapport;