import ActivityHeader from "components/ActivityHeader"
import AppHeader from "components/AppHeader"
import CardAction from "components/CardAction"

import "assets/styles/Actions.css"
import Global_header from "components/Global_header"
import data_pro from "components/data/project"
import imgenf from "assets/images/card_img3.jpeg"

import imgmomentum from "assets/images/momentum.png"

const Projects = ()=>{








    const Images = [
        `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
        "https://img.freepik.com/photos-gratuite/groupe-enfants-africains-apprenant-ensemble_23-2148892526.jpg",
        "https://img.freepik.com/photos-gratuite/petite-fille-souriante-bonheur-studio-portrait-sucette-douce_53876-146342.jpg",
        "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
        "https://img.freepik.com/photos-gratuite/enfants-africains-bras-croises_23-2148852698.jpg",
        "https://img.freepik.com/photos-gratuite/fille-smiley-coup-moyen-ecrit-ordinateur-portable_23-2148789725.jpg",
        "https://img.freepik.com/photos-gratuite/amis-heureux-etreignant-plan-moyen_23-2149479926.jpg",
        "https://img.freepik.com/photos-gratuite/gros-plan-smiley-filles-africaines-dehors_23-2148860429.jpg",
        "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
        `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
        "https://img.freepik.com/photos-gratuite/fille-smiley-coup-moyen-ecrit-ordinateur-portable_23-2148789725.jpg",
        "https://img.freepik.com/photos-gratuite/enfants-africains-bras-croises_23-2148852698.jpg",
    ]

    return(
        <>
          {/* <div className="details-actions-header">
                <div className="row justify-content-center align-items-center mx-auto">
                    <div className="col-md-10 text-center">
                        <h1 className="text-center">
                           Nos Projets
                        </h1>

                    </div>
                </div>
            </div> */}
            <Global_header ref_link='/' img={imgmomentum} title="Nos Projets" first_link="Accueil" second_link="Nos Projets" backgroundPosition="right 85% top 50%"/> 
            <div className="projet-header">
                <ActivityHeader 
                    title="Nos projets en chiffre"
                    content="Liste de nos projets à travers le pays."
                />
            </div>
            <div className="actions-list-container">
                {/* <h1 className="text-center">Nos Projets</h1> */}
                <div className="d-flex flex-wrap jusitfy-content-between align-items-center row">

                    {
                        data_pro?.map(elt=>(
                            <CardAction 
                            category={elt.volet}
                            link={"/projects-details/"+elt.id}
                            title={elt.titre}
                            img={elt.image}
                            text={elt.description}
                        />
                        ))
                    }
                </div>
            </div>

        </>
    )
}

export default Projects