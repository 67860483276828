import React,{ useState } from 'react';

import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    IconButton,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Collapse
  } from '@chakra-ui/react'
  import { HamburgerIcon } from '@chakra-ui/icons'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Link, NavLink } from 'react-router-dom';
import { Image } from '@chakra-ui/react'
import { BsFillDatabaseFill,BsFillFileEarmarkFill} from "react-icons/bs";
import AppButton, { AppButtonRapport, NavButton,AppButtonRapportIcon,NavButtonmodel, AppButtonRapportmodel } from './AppButton';
import OurMissionCard from "./OurMissionCard";
import logo from "assets/images/logo_ong_eicf.jpg"

import img_mission from "assets/images/mission.jpeg"

import img_objectif from "assets/images/nutrition.jpeg"

import img_valeurs from "assets/images/momentum.png"

import img_fondateur from "assets/images/founder.jpeg"

import img_directeur  from "assets/images/directeur3.jpeg"

import img_equipe  from "assets/images/equipe2.jpeg"

import 'assets/styles/Navbar.css';

import { BsFacebook,BsGoogle,BsQuestionLg, BsPenFill, BsPinAngleFill, BsPinFill, BsLightbulb } from 'react-icons/bs';


const listsOptionsWhous = [
    {
        title:"Qui sommes nous ",
        id:1,
        icon:<BsQuestionLg className='nav-incon-span'/>
        
    },
    {
        title:"Mission de l'ONG EICF ",
        id:2,
        icon:<BsPenFill className='nav-incon-span' />
    },
    // {
    //     title:"Objectifs spécifiques ",
    //     id:3,
    //     icon:<BsPinAngleFill className='nav-incon-span'/>
    // },
    {
        title:"Objectifs assignés ",
        id:4,
        icon:<BsPinFill className='nav-incon-span' />
    },
    {
        title:"Valeurs de l'ONG EICF ",
        id:5,
        icon:<BsLightbulb className='nav-incon-span'/>
    },
  ]
export default function Navbar(){
    const { isOpen, onOpen, onClose } = useDisclosure()
    const image = 'https://placeimg.com/640/480/tech'


    const [hidecolaps, sethidecolaps] = useState(true);
    const [hidecolaps2, sethidecolaps2] = useState(true);
    const onlink = ()=>{
        sethidecolaps(true)
        sethidecolaps2(true)
        console.log("active")
       
    }
    return(
        <nav className="container-fluid app-nav"  onClickAway={onlink}>
           
            <div className="navbar row-md">
                <div className="col-md-1 nav-logo text-center">
                        <NavLink className="nav-logo-container " to="/"  onClick={onlink}>
                            <Image  
                                src={logo} 
                                className="ong-eicf-logo" 
                                alt='logo ONG EICF' />
                        </NavLink>    
                </div>
                <div className="col-md-11 d-none d-md-block ">
                    <div className="d-flex justify-content-between  align-items-center py-2 nav-header-row">
                        <ul className="nav-header-ul" >
                            <li > <Link class="  nav-field-2  " to={"/offre"} onClick={onlink}>
                                    APPEL D'OFFRE
                                    </Link>
                            </li>
                            <li >

                            <Link class="  nav-field-2  " to={"/emploi"} onClick={onlink}>
                                    Emploi
                                    </Link>

                            </li>
                            
                        </ul>
                        <div className='d-flex'>
                        <AppButtonRapportIcon ico={<BsFillFileEarmarkFill />} title="BULLETINS TRIMESTRIELS" route="/rapport_trimestriel"/>
                            
                            <AppButtonRapport icon={<BsFillDatabaseFill />} title="RAPPORTS ANNUELS" route="/rapport" />
                        </div>
                    </div>
                    <div className="navbar-divider"></div>
                    <div className="d-flex justify-content-between align-items-center nav-button-container">
                        <div className="d-flex nav-button-menu">
                            <Button className='text-uppercase nav-field' as={Link}variant='ghost' rightIcon={<ChevronDownIcon />} onClick={() => {
                                sethidecolaps(s => !s)
                                sethidecolaps2(true)
                                }
                                }>
                               Qui sommes nous
                                
                            </Button>
                            <Button className='text-uppercase nav-field' as={Link}variant='ghost'   rightIcon={<ChevronDownIcon />} onClick={() => {sethidecolaps2(s => !s)
                             sethidecolaps(true)
                            
                            }}>
                               
                                    Gouvernance
                            </Button>

                           { <Button className='text-uppercase nav-field' as={Link}variant='ghost'  to='/where' onClick={onlink}>
                            Ou sommes nous ?
                            </Button>}

                            <Button className='text-uppercase nav-field' as={Link}variant='ghost'  to='/actions' onClick={onlink}>
                            Actions
                            </Button>
                            <Button className='text-uppercase nav-field' as={Link}variant='ghost'  to='/projects' onClick={onlink}>
                               
                                    Projets
                            </Button>
                            <Button className='text-uppercase nav-field ' as={Link}variant='ghost'  to='/actualite' onClick={onlink}>
                               
                                    Actualités
                            </Button>
                            {/* <Button className='text-uppercase nav-field ' as={Link}variant='ghost'  to='/contact' onClick={onlink}>
                               
                                    Nous contacter
                            </Button> */}
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-2 nav-other-button">
                        <NavButton title="NOUS CONTACTER" route="/contact" onClick={onlink}/>
                            <NavButton title="JE FAIS UN DON"route="/mon-don"  onClick={onlink}/>
                        </div>
                    </div>
                     
                </div>
                <div className="d-flex justify-content-between align-items-center gap-2  nav-other-button-phone">   
                            <NavButton title="JE FAIS UN DON" route='/mon-don'/>
                </div>

                <div className="nav-drawer-button-container">
                        <IconButton  onClick={onOpen}aria-label='open drawer' icon={<HamburgerIcon />} />
                    </div> 
            </div>
           {/* Collapse */}
          
            <div className={`row mb-2 mt-2 ${(hidecolaps) ? "hide_laps" : ""}`} > 
                        <div className='col-3'>
                                    <div class="list-group list-group-flush">
                                   
                                        {listsOptionsWhous?.map(({title,id,icon})=>(
                                            
                                            
                                            <Link class="list-group-item nav-field list-group-item-action  " to={`/who-us#${id}`} onClick={onlink}>
                                        
                                    <IconButton className="mx-2" rounded="2xl"  aria-label='facebook' icon={icon} />
                                        
                                    {title}
                                    </Link>
                                    ))}
                            
                                </div>
                        </div> 
                        <div className='col-3' onClick={onlink}>
                        <OurMissionCard imageSrc={ img_mission} linkText="Nos missions"/>
                        </div>  
                        <div className='col-3' onClick={onlink}>
                        <OurMissionCard imageSrc={img_objectif} linkText="Nos objectifs" />
                        </div>  
                        <div className='col-3' onClick={onlink}>
                        <OurMissionCard imageSrc={img_valeurs} linkText="Nos valeurs" />
                        </div>           

            </div>
            <div className={`row mb-2 mt-2 ${(hidecolaps2) ? "hide_laps" : ""}`} > 
                       
                        <div className='col-4' onClick={onlink}>
                        <OurMissionCard imageSrc={img_fondateur} linkText="Président Fondateur " link="/fondateur" />
                        </div>  
                        <div className='col-4' onClick={onlink}>
                        <OurMissionCard imageSrc={img_directeur} linkText="Directeur Exécutif" link="/directeur" />
                        </div>  
                        <div className='col-4' onClick={onlink}>
                        <OurMissionCard imageSrc={img_equipe} linkText="Equipe Administrative" link="/equipe" />
                        </div>           

            </div>
           <div className='row news_eicf '>
              
                <div className='col-12 text-center'>
                    <marquee>
                    !Break-News :  Prix du Meilleur Promoteur des Œuvres Sociales 2023 au AFRICA DUBAI BUSINESS AWARDS (ADBA) 6e ÉDITION.
                    </marquee>
                            
                </div>
           </div>
           <DrawerPhone 
           isOpenDrawer={isOpen}
           onCloseDrawer={onClose}
           />
        </nav>
    )
}



function DrawerPhone({ isOpenDrawer, onCloseDrawer }) {

    const [hidecolaps, sethidecolaps] = useState(true);
    const [hidecolaps2, sethidecolaps2] = useState(true);
    const image = 'https://placeimg.com/640/480/tech'
    const fermerModal = () => {
        onCloseDrawer();
      }
    return (
      <>
        <Drawer 
            placement={'left'} 
            onClose={onCloseDrawer} 
            isOpen={isOpenDrawer}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>
            <br/>
            <AppButtonRapportmodel icon={<BsFillDatabaseFill />} title="RAPPORTS TRIMESTRIELS" route="/rapport_trimestriel"  closemodal={fermerModal}/>
            <br/>
            <AppButtonRapportmodel icon={<BsFillDatabaseFill />} title="RAPPORTS ANNUELS" route="/rapport" closemodal={fermerModal}/>
            <br/>
            <div class="list-group list-group-flush text-center">
                
                 <Link class="list-group-item list-group-item-action  nav-field  " to={"/offre"} onClick={fermerModal}>
                 APPEL D'OFFRE
                                    
                    </Link>
               
                <Link class="list-group-item list-group-item-action  nav-field  " to={"/emploi"} onClick={fermerModal}>
                 Emplois
                                    
                    </Link>
            </div>
            <DrawerCloseButton  />
            </DrawerHeader>
            <DrawerBody>
            <Button className='text-uppercase nav-field' as={Link}variant='ghost' rightIcon={<ChevronDownIcon />} onClick={() => {
                                sethidecolaps(s => !s)
                                sethidecolaps2(true)
                                }
                                }>
                               Qui sommes nous
                                
            </Button>
            <div className={`row mb-2 mt-2 ${(hidecolaps) ? "hide_laps" : ""}`} > 
                        <div className='col-12'>
                                    <div class="list-group list-group-flush">
                                        {listsOptionsWhous?.map(({title,id,icon})=>(
                                            
                                            
                                            <Link class="list-group-item nav-field list-group-item-action  " to={`/who-us#${id}`}>
                                        
                                    <IconButton className="mx-2" rounded="2xl"  aria-label='facebook' icon={icon} />
                                        
                                    {title}
                                    </Link>
                                    ))}
                            
                                </div>
                        </div> 
                        <div className='col-12'>
                        <OurMissionCard imageSrc={img_mission} linkText="Nos missions"  onClick={fermerModal}/>
                        </div>  
                        <div className='col-12'>
                        <OurMissionCard imageSrc={img_objectif} linkText="Nos objectifs" onClick={fermerModal}/>
                        </div>  
                        <div className='col-12'>
                        <OurMissionCard imageSrc={img_valeurs} linkText="Nos valeurs" onClick={fermerModal}/>
                        </div>           

            </div>
            <Button className='text-uppercase nav-field' as={Link}variant='ghost'   rightIcon={<ChevronDownIcon />} onClick={() => {sethidecolaps2(s => !s)
                             sethidecolaps(true)
                            
                            }}>
                               
                                    Gouvernance
            </Button>
            <div className={`row mb-2 mt-2 ${(hidecolaps2) ? "hide_laps" : ""}`} > 
                       
                       <div className='col-12'>
                       <OurMissionCard imageSrc={img_fondateur} linkText="Président Fondateur" link="/fondateur" onClick={fermerModal}/>
                       </div>  
                       <div className='col-12'>
                       <OurMissionCard imageSrc={img_directeur} linkText="Directeur executif" link="/directeur" onClick={fermerModal}/>
                       </div>  
                       <div className='col-12'>
                       <OurMissionCard imageSrc={img_equipe} linkText="Equipe Administrative" link ="/equipe" onClick={fermerModal}/>
                       </div>           

           </div>
           <Button className='text-uppercase nav-field' as={Link}variant='ghost'  to='/where'onClick={fermerModal} >
                            Ou sommes nous ?
                            </Button>
                            <Button className='text-uppercase nav-field' as={Link}variant='ghost'  to='/actions' onClick={fermerModal}>
                            Actions
                            </Button>
                            <Button className='text-uppercase nav-field' as={Link}variant='ghost'  to='/rapport' onClick={fermerModal}>
                               
                                    Projets
                            </Button>
                            <Button className='text-uppercase nav-field' as={Link}variant='ghost'  to='/actualite' onClick={fermerModal}>
                               
                                    Actualités
                            </Button>
              <NavButtonmodel title="NOUS CONTACTER" route="/contact" closemodal={fermerModal}/>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }