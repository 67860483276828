import ActivityHeader from "components/ActivityHeader";
import AppHeader from "components/AppHeader";
import CardAction from "components/CardAction2";
import "assets/styles/Actions.css";
import img_fondateur from "assets/images/directeur2.jpeg";
import equipe1 from "assets/images/equipe1.jpeg";
import equipe2 from "assets/images/equipe2.jpeg";
import equipe3 from "assets/images/equipe3.jpeg";
import vero from "assets/images/vero.jpg";
import eric from "assets/images/eric.jpg";
import dona from "assets/images/dona.jpg";
import rapport_annuel from "assets/images/rapport_annuel.png";
import pdf from "assets/images/pdf.png";
import rapport_pmndpe from "assets/images/rapport_pmndpe.png";
import rapport_projetswedd from "assets/images/rapport_projetswedd.png";
import rapport1 from "assets/images/rapport1.png";

import rapport_annuel_pdf from "assets/images/rapport_annuel.pdf";
import rapport_pmndpe_pdf from "assets/images/rapport_pmndpe.pdf";
import rapport_projetswedd_pdf from "assets/images/rapport_swedd.pdf";
import rapport1_pdf from "assets/images/rapport1.pdf";
import Global_header from "components/Global_header";
import data_rapport from "components/data/rapport";
import img_nutri from "assets/images/franc.jpeg"
const Actions = () => {
  const Images = [
    `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
    "https://img.freepik.com/photos-gratuite/groupe-enfants-africains-apprenant-ensemble_23-2148892526.jpg",
    "https://img.freepik.com/photos-gratuite/petite-fille-souriante-bonheur-studio-portrait-sucette-douce_53876-146342.jpg",
    "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
    "https://img.freepik.com/photos-gratuite/enfants-africains-bras-croises_23-2148852698.jpg",
    "https://img.freepik.com/photos-gratuite/amis-heureux-etreignant-plan-moyen_23-2149479926.jpg",
    "https://img.freepik.com/photos-gratuite/gros-plan-smiley-filles-africaines-dehors_23-2148860429.jpg",
    "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
    `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
  ];

  return (
    <>
     <Global_header ref_link='/' img={img_nutri } title="Rapports Annuels EICF" first_link="Accueil" second_link="Rapports Annuels EICF"/>
     

      <div className="row mt-4">
        <div class="col-lg-12 col-md-12 col-xs-12 col-">
          <center>
            <div className="title_founder3"></div>
          </center>
        </div>
      </div>

      <div className="row mt-4">
      {data_rapport?.map(elt=>(
             <div className="col-lg-4 col-md-4 col-12 col-xs-12 col-sm-12">
         
             <CardAction
               title={elt.titre}
               img={pdf}
               text=""
               link={elt.pdf}
             />
           </div>
            ))}
    
      </div>
    </>
  );
};

export default Actions;
