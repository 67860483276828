import 'assets/styles/Contact.css';
import { ListItem,OrderedList,Select,Card, CardHeader, CardBody, CardFooter,Button,ButtonGroup,Textarea,Image}  from '@chakra-ui/react'
import Myemploi from 'components/Emploi';
import Myactualite from 'components/Myactualite';
import { useParams } from 'react-router-dom';
import Img from "assets/images/recrutement.png"
import paix_et_cohesion  from 'assets/images/paix_et_cohesion sociale.jpg';
import Myactualitedetail from 'components/Myactualitedetail';
import Myactu from 'components/Myactu';
import Global_header from "components/Global_header"
import data_actu from 'components/data/actualite';
import img_action from "assets/images/mission.jpeg"
const DetailActu =()=>{
    const { id } = useParams();
    const data = data_actu.find(function(elts) {
        return elts.id === JSON.parse(id) ;
      })
     
    return (
        <div>
           <Global_header ref_link='/' img={img_action} title="Détail Actualité" first_link="Accueil" second_link="Détail Actualité"/> 
            <div className='container-fluid   mt-5'>
                
                <div className='row '>
                        
                        <div className='col-lg-8'>
                            <div className='row'>
                            <Myactu img={data.image} title={data.titre}  date={data.date} description={data.description} />
                            
                            
                            </div>
                        </div>
                        <div className='col-lg-4 '>
                            <div className='row'>
                              <div className='col-lg-12'>
                              <h4 className='text-center'>Autres actualités récentes </h4>
                              </div>
                              <div className='col-lg-12'>
                                <div className='row'>
                                    {
                                        
                                        data_actu?.map((elt,index)=>(
                                   
                                       (index<3 && elt.id!=id)?<Myactualitedetail mini_description={elt.mini_description} to={"/actu/"+elt.id} img={elt.image } title={elt.titre}  date={elt.date} description={elt.description} />:null
                                                
                                            
                                                
                                             
                                           
                                        ))
                                    }
                               
                            
                                </div>
                              </div>
                              
                            </div>
                        
                        </div>
                      
                </div>
            </div>
       </div>
    )
}

export default DetailActu