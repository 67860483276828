import 'assets/styles/Contact.css';
import { ListItem,OrderedList,Select,Card, CardHeader, CardBody, CardFooter,Button,ButtonGroup,Textarea,Image}  from '@chakra-ui/react'
import Myemploi from 'components/Emploi';
import Img from "assets/images/recrutement.png"
import Global_header from "components/Global_header"

import data_appel from 'components/data/appeloffre';
import img_action from "assets/images/Procurement.jpg"
const Offre =()=>{
 
    return (
        <div>
           <Global_header ref_link='/' img={img_action} title="Appel d'Offre " first_link="Accueil" second_link="Appel d'Offre" backgroundPosition="right 85% top 20%" /> 
            <div className='container-fluid   mt-5'>
                
                <div className='row '>
                        <div className='col-lg-4 '>
                        
                        <img src={Img} alt="ONG EICF" className="img-fluid mx-auto  rounded-top" />
                        </div>
                        <div className='col-lg-8'>
                            <div className='row'>
                             
                            
                            {
                                ( data_appel.length!=0)?
                                data_appel?.map(elt=>(
                                    <Myemploi title={elt.titre} date={elt.date_expiration} description={elt.description} pdf={elt.pdf}/>
                                    )):(
                            <div className='col-lg-12 text-center mt-5'>
                                <h2> Pas d'offre d'appel disponible actuellement</h2>
                            </div>

                        )
                                }
                            
                            </div>
                        </div>
                      
                </div>
            </div>
       </div>
    )
}

export default Offre