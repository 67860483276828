import "assets/styles/Global_header.css"
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import { Link } from "react-router-dom"

export default  function Global_header (props){


    return(
        <div className='global_header col-lg-12 pb-5' > 
            <Card className="global_header_zone" style={{
            background: `linear-gradient(360deg,  #75b72a93, transparent  ), url(${ props.img })`,
            backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: props.backgroundPosition?props.backgroundPosition:"right 85% top 30%",
          }}>
               
                <CardBody>
                    <div className="global_header1 text-center">
                    {props.title}
                    </div>
                    <div className="global_header2 text-center">
                        <Link to={props.ref_link} className="header_first_link">
                        {props.first_link}
                        </Link> 
                         > {props.second_link}
                    </div>
                </CardBody>
               
            </Card>
        </div>
    )
}