
import { Link } from "react-router-dom";
import cardImg from "assets/images/card_img.jpeg"
import { useNavigate } from "react-router-dom";
import "assets/styles/CardAction.css"
import { Button } from "@chakra-ui/react";

const CardAction =  ({text,title,link,img})=>{
    const navigate = useNavigate();


    const showActionDetails = ()=>{
      
        navigate(!!link?link:"/actions")
    }

    return(
        <>
        <div 
            className="card-action col-lg-12 col-md-12 col-xs-12 col-sm-12 col-12 shadow mt-4 mx-md-12 rounded-4"
            onClick={showActionDetails}>
            <div>
                <div className="card-action-image-container col-lg-12 col-md-12 col-xs-12 col-sm-12">
                    <img src={img} alt="ONG EICF" style={{backgroundSize:"cover"}} className="img-fluid   w-100 rounded-top" />
                </div>
                <div className="card-action-body1 px-3 text-center pt-3">

                    <h4 className="fw-bolder pt-2" > {title} </h4>
                    
                    <h6 className="pt-2 " > {text} </h6>
                   
                   {/* <Link className="card-link" to={!!link?link:"#"}>En savoir plus </Link> */}
                   
                </div>
       
            </div>
        </div>
        </>
    )
}

export default CardAction