import React from 'react';
import Slider from "react-slick";
import DonateButton from './DonateButton';
import "assets/styles/Slide.css"
const { default: AppButton } = require("./AppButton")

const Slide = ({firstContent,secondContent,imageSrc,showButton=true,to,key}) => {
  return (
 
<div className="header" style={{
  backgroundImage:`
  url(${imageSrc})`,
  backgroundSize:'cover',
  overflowX: 'hidden',
  overflowY: 'hidden',
  padding: '0.5rem',
  paddingTop: '100px',
  height: '600px',
  marginTop: '9rem',
  color: '#fff',
  borderRadius: '2rem',
}}>
<div className="row">
    <div className="col-lg-6 col-md-6">
          <div className="banncard" >
            <h5 >{firstContent}</h5>
            <p>{secondContent} </p>
            { showButton && <AppButton title="En savoir plus" to={to} />}
          </div>
        
    </div>
</div>
</div>




  );
}

const MySlider = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const slides = props.slides.map((slide, index) => {
    return (
      <Slide key={index} {...slide} />
    );
  });

  return (
    <Slider {...settings}>
      {slides}
    </Slider>
  );
}

export default MySlider;
