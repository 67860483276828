import { useRef } from 'react';

import { List,  ListItem,  ListIcon,IconButton, Tabs, TabList, Tab } from '@chakra-ui/react'
import { FaRegStar } from 'react-icons/fa';
import { BsFacebook,BsGoogle } from 'react-icons/bs';
import OurMissionCard from "components/OurMissionCard";
import MySlider from "components/MySlider3";
import {AppButton , NavButton} from "components/AppButton";
import "assets/styles/WhoUs.css"
import "assets/styles/Slide.css"
import objectif  from 'assets/images/directeur2.jpeg';
import Global_header from 'components/Global_header';
import { Link } from 'react-router-dom';
import img_nutri from "assets/images/atelier3.jpeg"

export const listsOptionsWhous = [
    {
        title:"Qui sommes nous ",
        id:1
    },
    {
        title:"Mission de l'ONG EICF ",
        id:2
    },
    // {
    //     title:"Objectifs spécifiques ",
    //     id:3
    // },
    {
        title:"Objectifs assignés ",
        id:4
    },
    {
        title:"Valeurs de l'ONG EICF ",
        id:5
    },
  ]

const WhoUs = ()=>{
    const componentRefs = useRef([]);
    const image = 'https://placeimg.com/640/480/tech'

    const slides = [
        { 
            firstContent: "1.	Contribuer à la réduction de la pauvreté et du chômage en milieu rural et urbain à travers les projets d’insertion socioéconomiques (Entrepreneuriat) des jeunes et des femmes;", 
      
        },
        { 
            firstContent: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte.imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte", 
            secondContent: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte.", 
            image: 'https://placeimg.com/640/480/people' 
        },
        { 
            firstContent: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texteimprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte.", 
            secondContent: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte.", 
            image: 'https://placeimg.com/640/480/animals' 
        },
      ];




    const handleItemClicked = (index) => {
        // Faire défiler vers le composant référencé
        if (componentRefs.current[index]) {
        componentRefs.current[index].scrollIntoView({ behavior: 'smooth' });
        }
    };


    return(
        <>
        <div className='margin_who_us'>
        <Global_header ref_link='/' img={img_nutri} title="Qui sommes nous" first_link="Accueil" second_link="Qui sommes nous"/>
            <Tabs className='justify-content-center d-flex text-center'>
            <TabList>
                {
                    listsOptionsWhous?.map(({title,id})=>(

                        <Tab onClick={()=>handleItemClicked(id)}
                            key={id}
                            _selected={{ color: 'var(--color-primary-yellow)', bg: 'white' }}
                             >
                            {title}
                        </Tab>
                    ))
                }
            </TabList>

            </Tabs>
        </div>
            <div 
                ref={(el) => (componentRefs.current[1] = el)} 
                className="who-us-content mt-5">
                <h1 className="fw-bolder text-center" >
                    Qui sommes nous ?
                </h1>
                <div className="row justify-content-between align-items-center py-4">
                   
                      <center>

                        <div className="col-md-6 py-2">
                        <div className="divider"></div>
                        <p>
                            ENSEMBLE IMAGINONS ET CONSTRUISONS LE FUTUR (EICF) est une Organisation Non Gouvernementale (ONG) créée le 11 Février 2008 et qui œuvre pour l’atteinte des  ODD.en apportant le mieux être aux populations les plus vulnérables en Côte d’Ivoire et partout en Afrique.
                        </p>
                        <div className="divider"></div>
                    </div>
                      </center>
                    
                </div>

                

                <div className="other-mission-indicator container ">
                    <div className="row justify-content-center align-items-center">
                        <div className="col">
                            <p>

                                <span className="span-colored">Notre histoire : </span>
                             EICF c'est 15 ans d'engagement et de solidarité , des milliers de personnes impactées et plus de 30 projets
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div 
                ref={(el) => (componentRefs.current[2] = el)} 
                className="mt-5 pt-5 pb-5 mb-5">
                <div className="row justify-content-center align-items-center text-center">
                    <div className="col-md-9">
                        <h1 className="fw-bolder">
                            Missions de l'ONG EICF
                        </h1>
                        <p className="my-4">
                        L'ONG EICF s'est assignée de nombreuses missions pour reduire la pauvreté en Afrique 
                        </p>
                    </div>
                </div>
                
                <div className="our-worth-mission-indicator ">
                    <div className="container">
                        <div className="d-flex justify-content-center align-items-stretch">
                            <div className="our-worth-mission-indicator-style"></div>
                            <p className="our-worth-mission-indicator-text" >
                               <p>1.	Contribuer à la réduction de la pauvreté et du chômage en milieu rural et urbain à travers les projets d’insertion socioéconomiques (Entrepreneuriat) des jeunes et des femmes;</p> 
                              <p>
                                2.	Éliminer la faim, assurer la sécurité alimentaire, améliorer la nutrition et promouvoir une agriculture durable ;
                              </p>
                              <p>
                                3.	Sensibiliser la population sur la Santé maternelle et le Planning familial, sur les pandémies qui minent notre société (VIH-SIDA, PALUDISME, la POLIO, Diabète, l’Hypertension…..) à travers des programmes financés par les bailleurs de fonds;
                              </p>

                              <p>
                                4.	Veiller à ce que tous les enfants puissent avoir une éducation de qualité dans des conditions d’équité et promouvoir les possibilités d’apprentissage tout au long de la vie;
                              </p>

                              <p>
                                5.	Faire la promotion de l’entrepreneuriat sur le plan national à travers la promotion de l’esprit d’entreprise et de ses Programmes d’Insertion et d’Appui Communautaire;
                              </p>

                              <p>
                                6.	Contribuer à la lutte contre l’ignorance du monde rural (femmes, jeunes, adultes) en leur donnant le savoir lire et écrire par l’alphabétisation ;
                              </p>

                              <p>
                                7.	Promouvoir les droits de l’homme dans le contexte sociopolitique actuel de notre pays mais en particulier ceux de la Femme et de la Jeune Fille qui constituent la couche la plus vulnérable de la population ;
                              </p>

                              <p>
                                8.	Promouvoir l’alphabétisation des femmes et des jeunes filles et leur autonomisation socio-économique;
                              </p>

                              <p>
                                9.	Sensibiliser la population au renforcement du dialogue communautaire et de la cohésion sociale sur le plan national ;
                              </p>

                              <p>
                                10.	Appuyer le gouvernement dans sa politique de développement durable à travers des projets de sensibilisation sur la protection de l’environnement et sur les énergies renouvelables ;
                              </p>


                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div 
                >
              
                <div 
                    ref={(el) => (componentRefs.current[4] = el)} 
                    className="row justify-content-between align-items-center">
                    <div className="col-md-6 my-3 ">
                        <h2 className="fw-bolder text-center">
                            Objectifs assignés
                        </h2>
                        <p>
                           
                            Notre objectif est de contribuer à l’atteinte des  <span className="span-colored"> Objectifs De Développement Durable (ODD)  </span> en apportant le mieux être aux populations les plus vulnérables en Côte d’Ivoire et partout en Afrique.
                            
                            
                        </p>

                    </div>
                    <div className="col-md-6 my-3 text-center">
                        <div className="slide-img-content">
                            <img className="img-fluid" src={objectif} alt="objectif img" />
                        </div>
                    </div>
                </div>

               
            </div>
            <div 
                ref={(el) => (componentRefs.current[5] = el)}  
                className="our-worth">
                <div className="">
                    <h1 className="fw-bolder text-center pt-5 pt-4">
                        Valeurs de l'ONG EICF
                    </h1>
                    
                </div>

                <div className="our-worth-mission-indicator ">
                    <div className="container">
                        <div className="d-flex justify-content-center align-items-stretch">
                            <div className="our-worth-mission-indicator-style"></div>
                            <p className="our-worth-mission-indicator-text" >
                                <p>1-La Rigueur </p>
                                <p>2-La Transparence et l’impartialité </p>
                                <p>3- L’Esprit d’Equipe  </p>
                                <p>4-  La Discrétion   </p>
                                <p>5-  La Redevabilité   </p>
                              
                            </p>
                        </div>
                    </div>
                </div>
                <div className="show-more">
                    <div className="text-center mb-md-5 mb-3">
                        <NavButton title="Découvrir tous nos  domaines d'interventions" route="/actions"  />
                    </div>
                    <div className="show-more-content">
                        <h1 className="fw-bolder text-white text-center pt-5 pt-4">
                            En savoir plus sur l'ONG EICF
                        </h1>

                       
                        <div className="d-flex align-items-stretch">
                        <Link to="/rapport" className="show-more-report-button">
                        
                                
                                    voir tous nos rapports
                          
                        </Link>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhoUs