import ActivityHeader from "components/ActivityHeader"
import AppHeader from "components/AppHeader"
import CardAction from "components/CardAction1"
import "assets/styles/Actions.css"
import img_fondateur from "assets/images/New/wilson.jpg"
import equipe1 from "assets/images/equipe1.jpeg"
import equipe2 from "assets/images/equipe2.jpeg"
import equipe3 from "assets/images/equipe3.jpeg"
import vero from "assets/images/New/vero.jpg"
import eric from "assets/images/New/eric.jpg"
import dona from "assets/images/New/dona.jpg"
import rse from "assets/images/New/rse.jpg"
import Global_header from "components/Global_header"
import img_action from "assets/images/equipe2.jpeg"
const Actions = ()=>{

    const Images = [
        `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
        "https://img.freepik.com/photos-gratuite/groupe-enfants-africains-apprenant-ensemble_23-2148892526.jpg",
        "https://img.freepik.com/photos-gratuite/petite-fille-souriante-bonheur-studio-portrait-sucette-douce_53876-146342.jpg",
        "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
        "https://img.freepik.com/photos-gratuite/enfants-africains-bras-croises_23-2148852698.jpg",
        "https://img.freepik.com/photos-gratuite/amis-heureux-etreignant-plan-moyen_23-2149479926.jpg",
        "https://img.freepik.com/photos-gratuite/gros-plan-smiley-filles-africaines-dehors_23-2148860429.jpg",
        "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
        `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
    ]

    return(
        <>
       
        <Global_header ref_link='/' img={img_action} title="Gouvernance" first_link="Accueil" second_link="Gouvernance > Equipe Administrative" backgroundPosition="right 70% top 20%"/>

        <div className="row mt-4">

            <div class="col-lg-12 col-md-12 col-xs-12 col-">

             <center>
                <h2 className="title_founder5" > Administration
                 <div className="title_founder3">                 
                 </div>

                </h2>
             </center>

            </div>
       </div>
      
               <div className="row mt-4">

                <div className="col-lg-4 col-md-4 col-12 col-xs-12 col-sm-12">


                 
                </div>

                <div className="col-lg-4 col-md-4 col-12 col-xs-12 col-sm-12">

                <CardAction 
                    title="President directeur executif "
                    img={img_fondateur}
                    text="WILSON KOFFI BROU "
                 />
                 
                </div>

                <div className="col-lg-4 col-md-4 col-12 col-xs-12 col-sm-12">


                 
                </div>

                

               </div>


               <div className="row mt-4">


               <div className="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-12 col-xs-12 col-sm-12">

               <CardAction 
                  title="Responsable administratif et financier"
                   img={eric}
                   text="ERIC DOFFOU"
                />

                 
                </div>

                <div className="col-lg-4 col-md-4 col-12 col-xs-12 col-sm-1">

                <CardAction 
                  title="Responsable ressources humaines"
                   img={vero}
                   text="VERONIQUE N'DA "
                />
 
              </div>

               <div className="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-12 col-xs-12 col-sm-12">

               <CardAction 
                  title="Responsable programme"
                   img={dona}
                   text="DONALD KOUAME "
                />
 
               </div>
               <div className="col-lg-4 col-md-4 col-12 col-xs-12 col-sm-12">

                     <CardAction 
                        title="Responsable suivi et évaluation"
                        img={rse}
                        text="Guina Toussain "
                     />

                     </div>



              </div>

              <div className="row mt-4">

                   <div class="col-lg-12 col-md-12 col-xs-12 col-">

                    <center>
                       <h2 className="title_founder5" >Nos bureaux executifs
                        <div className="title_founder3">                 
                        </div>

                       </h2>
                    </center>

                   </div>
              </div>


              <div className="row mt-4">

                <div class="col-lg-12  col-md-12 col-xs-12 col-12">

                 <center>
                    <img   style={{height: '600px'}} className="img-fluid represent " src= {equipe2}/>
                    <p  style={{fontWeight: '900' , fontSize: '20px'}} >Bureau siége EICF ABIDJAN 
                      
                    </p>
                </center>

                 

                </div>
           </div>
         

           <div className="row mt-4">

            <div class="col-lg-12  col-md-12 col-xs-12 col-12">

             <center>
                <img   style={{height: '600px'}} className="img-fluid represent " src= {equipe1}/>
                <p  style={{fontWeight: '900' , fontSize: '20px'}} >Bureau  EICF BOUNA 
                  
                </p>
            </center>

             

            </div>
       </div>


       <div className="row mt-4">
        <div class="col-lg-12  col-md-12 col-xs-12 col-12">

         <center>
            <img   style={{height: '600px'}} className="img-fluid represent" src= {equipe3}/>
            <p  style={{fontWeight: '900' , fontSize: '20px'}} >Bureau EICF BONDOUKOU
              
            </p>
        </center>

         

        </div>
   </div>


        </>
    )
}

export default Actions