import ActivityHeader from "components/ActivityHeader"
import AppHeader from "components/AppHeader"
import CardAction from "components/CardAction"
import "assets/styles/Actions.css"
import img_fondateur from "assets/images/founder.jpeg"
import Global_header from "components/Global_header"
import img_action from "assets/images/equipe2.jpeg"
const Actions = ()=>{

    const Images = [
        `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
        "https://img.freepik.com/photos-gratuite/groupe-enfants-africains-apprenant-ensemble_23-2148892526.jpg",
        "https://img.freepik.com/photos-gratuite/petite-fille-souriante-bonheur-studio-portrait-sucette-douce_53876-146342.jpg",
        "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
        "https://img.freepik.com/photos-gratuite/enfants-africains-bras-croises_23-2148852698.jpg",
        "https://img.freepik.com/photos-gratuite/amis-heureux-etreignant-plan-moyen_23-2149479926.jpg",
        "https://img.freepik.com/photos-gratuite/gros-plan-smiley-filles-africaines-dehors_23-2148860429.jpg",
        "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
        `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
    ]

    return(
        <>
        
        <Global_header ref_link='/' img={img_action} title="Gouvernance" first_link="Accueil" second_link="Gouvernance > Fondateur" backgroundPosition="right 70% top 20%"/>        
      
               <div className="row mt-4">

                <div className="col-lg-6 col-md-6">

                    <div className="slide-img-content">
                        <img className="img-fluid" src= {img_fondateur}/>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12 mt-4">

                    <h2 className="mb-5">Discours du président fondateur

                        <div className="title_founder col-lg-8 ml-8">
                           
                        </div>
                    </h2>

                    <div>
                        <p> Chers amis, partenaires, acteurs, artisans et visionnaires du changement,</p>
                        <p> C'est avec un immense honneur et une profonde gratitude que je vous accueille sur le site web de l'ONG EICF (Ensemble Imaginons et Construisons le Futur). Nous sommes réunis ici dans un esprit de collaboration, d'innovation et de détermination pour façonner un avenir meilleur pour tous.</p>
                        <p> Depuis sa fondation, EICF s'est engagée à incarner une vision audacieuse : celle d'un monde où chaque individu a la possibilité de réaliser son plein potentiel, où les communautés prospèrent dans l'harmonie en connaissant un mieux-être et où la planète est préservée pour les générations futures.</p>
                        <p>Notre voyage vers cet idéal est guidé par une conviction profonde : que le changement durable ne peut se produire que lorsque nous unissons nos forces, nos idées et nos ressources. En tant que membres de la famille EICF, nous croyons fermement en la puissance de l'action collective pour résoudre les défis les plus pressants de notre époque.</p>
                        <p>Aujourd'hui, alors que nous nous tenons à la croisée des chemins, nous sommes confrontés à des défis complexes et interconnectés dans notre pays la Côte d’Ivoire et partout en Afrique : l’extrême pauvreté qui mine nos sociétés africaines, l’analphabétisation des populations qui constitue un frein à une pleine éducation et à l’auto-détermination des peuples, les inégalités homme-femme qui persistent, le changement climatique qui menace notre environnement et les crises socio-politiques de plus en plus destructrices. nous refusons de céder au désespoir. Au contraire, nous voyons ces défis comme des opportunités de catalyser le changement, d'innover et de créer une Afrique et un monde plus juste et plus durable.</p>
                        
                       
                    </div>
                
                </div>
                <div className="col-lg-12">
                <p>Dans cette perspective, EICF s'engage à être un catalyseur de progrès. Nous nous efforçons de développer des solutions novatrices, de soutenir les initiatives locales et de mobiliser les communautés pour une Afrique meilleure. Que ce soit par le biais de programmes de développement social, d’entrepreneuriat social, de programmes éducatifs, de projets de développement durable ou d'actions de plaidoyer, nous sommes déterminés à faire une différence tangible dans la vie des personnes que nous servons.</p>
                <p>Toutefois, nous savons que seuls, nous ne pouvons accomplir cette mission. Nous avons besoin de vous, de votre passion, de votre expertise et de votre engagement. En travaillant ensemble, nous pouvons transformer les rêves en réalité, les obstacles en opportunités et les défis en triomphes.</p>
                        <p>En parcourant ce site web, je vous invite à explorer nos initiatives, à vous impliquer dans nos projets et à vous joindre à notre mouvement mondial pour le changement. Ensemble, nous pouvons imaginer un avenir meilleur et le construire, pas à pas, jour après jour.</p>
                        <p>Au nom de toute l'équipe d'EICF, je vous remercie de votre soutien et de votre confiance. Ensemble, nous sommes plus forts. Ensemble, nous pouvons façonner un avenir où chaque voix compte, où chaque personne est valorisée et où chaque rêve peut devenir réalité.</p>
                        <p>Bienvenue dans la communauté EICF. </p>
                        <p>Ensemble, sauvons de vies pour un avenir meilleur pour tous.</p>
                        <p>Avec gratitude et détermination,</p>
                        <b>BROU N’GUESSAN Albert
                            Président Fondateur, ONG EICF (Ensemble Imaginons et Construisons le Futur)
                            </b>
                      
                        <p className="sign_founder"> PRESIDENT FONDATEUR EICF </p>
                </div>
               </div>
         


        </>
    )
}

export default Actions