import { Link } from "@chakra-ui/react"
import "assets/styles/DetailsActions.css"
import ActivityHeader from "components/ActivityHeader"
import MySliderpro from "components/MySliderpro"
import Global_header from "components/Global_header"
import { useState } from "react"
import { useParams } from 'react-router-dom';

import dev_comm from "assets/images/developpement_comunautaire.jpg";
import entrep from "assets/images/entrepreuneuriat.jpg";
import sante from "assets/images/nutrition.jpg";
import alphab from "assets/images/alphabetisation.jpg";
import action_social from "assets/images/action_sociale.jpg";
import droit_de_lhomme from "assets/images/droit_de_homme.jpg";
import paix_cohesion from "assets/images/paix_et_cohesion sociale.jpg";
import dev_durable from "assets/images/environnement.jpg";

import data_action from "components/data/action";
const image = 'https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg'
const slides = [
    {
        image:image,
        to:"#",
        title:"Projet 1",
        text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."
    },
    {
        image:image,
        to:"#",
        title:"Projet 2",
        text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."
    },
    {
        image:image,
        to:"#",
        title:"Projet 3",
        text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."
    },
    {
        image:image,
        to:"#",
        title:"Projet 4",
        text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."
    }

]

const OtherMissionCard = ({title,text,image,to})=>{
    const [mouseHover,setMouseHover] = useState(false)

    const cardStyle= mouseHover ?`linear-gradient(to bottom,rgba(200, 200, 10, 0), var(--color-primary-yellow-3)),url(${image})` :
     `linear-gradient(to bottom,rgba(200, 200, 10, 0), rgba(200, 200, 200,0), rgba(0, 0, 0, 0.99)),url(${image})`
    return(
        <Link to={to}>
        <div 
            className="other-mission-card shadow"
            style={{backgroundImage:cardStyle}}
            onMouseEnter={()=>setMouseHover(true)}
            onMouseLeave={()=>setMouseHover(false)}
        >
            <div className="row justify-content-center align-items-end">
                <div className="col">
                    <h3 
                        className="fw-bolder"
                        style={{color: `${mouseHover ? "#000":"#fff"}`}} 
                    > 
                    {title} 
                    </h3>
                    <p
                        className="fw-bolder"
                        style={{color: `${mouseHover ? "#fff":"var(--color-primary-yellow-3)"}`}} 
                    >
                        {text} 
                    </p>
                </div>
            </div>
        </div>
        </Link>
    )
}


const DetailsActions = ()=>{
 
    const { id } = useParams();
    const data = data_action.find(function(elts) {
        return elts.id === JSON.parse(id) ;
      })
    return(
        <>
           
            <Global_header ref_link='/' img={data.image} title={data.titre} first_link="Accueil" second_link="Détail Action"/> 
            <div className="our-mission-container container-fluid">
                <h2>
                    Description de l'action
                </h2>
                <div className="row justify-content-center align-items-center mt-4">
                    <div className="col-md-6">
                        <p>
                           {data.description}
                        </p>
                        { <p>
                            {  data.liste?.map(elt=>(
                             <li className="span-colored">{elt}</li>
                            ))
                            }
                        </p>
                        /*<p>
                            Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, <span className="span-colored">quand un imprimeur anonyme</span> assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que 
                        </p> */}
                    </div>
                    <div className="col-md-6  mt-md-0 mt-3">
                        <div className="our-mission-container-img-content">
                            <img className="img-fluid" src={data.image} alt="slide-img" />
                        </div>
                    </div>
                </div>
            </div>
         
            {/* <div className="other-mission-container">
                <div className="row justify-content-center align-items-center ">
                    <div className="col-md-10 mt-md-0 mt-3 text-center">
                        <h2>
                           NOS OEUVRES EN DETAIL
                        </h2>
                     
                    </div>
                </div>
                <div className="other-mission-card-container">
                    <div className="other-mission-card-content ">
                     
                        <OtherMissionCard 
                            image={image}
                            to="#"
                            title="Projet 1"
                            text="Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."
                        />
                        <OtherMissionCard 
                            image={image}
                            to="#"
                            title="mise en page avant impression"
                            text="Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."
                        />
                        <OtherMissionCard 
                            image={image}
                            to="#"
                            title="mise en page avant impression"
                            text="Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."
                        />
                    </div>
                </div>
            </div> */}

            {/* <div className="other-mission-indicator ">
                <div className="row justify-content-center align-items-center">
                    <div className="col">
                        <p>
                            Le Lorem Ipsum est simplement du faux texte employé dans .<span className="span-colored">composition et la mise en page avant impression.</span> Le Lorem 
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5">  
                <ActivityHeader
                    title="L'ONG EICF en chiffre"
                    content="Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que "
                />
            </div> */}
        </>
    )
}

export default DetailsActions