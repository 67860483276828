import "assets/styles/AppButton.css"
import "assets/styles/Myactu.css"
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import { Link } from "react-router-dom"
export default  function Myactu (props){

    return(
        <div className='col-lg-12 pb-5'> 
            <Card>
                <div className="row">
                <div className="col-lg-12  text-center">
                    <img src={props.img} className=" img-fluid mx-auto" />
                    </div>
                    <div className="col-lg-12">
                        <CardHeader className="h4">
                        {props.title}
                        </CardHeader>
                    </div>
                    
                    <div className="col-lg-12">
                        <CardBody>
                            <div className="h5">
                            {props.date}
                            </div>
                            
                         </CardBody>
                        <CardBody>
                            <div className="P ">
                            {props.description}
                            </div>
                            
                         </CardBody>
                      
                    </div>
                </div>
               
               
               
            </Card>
        </div>
    )
}