import ActivityHeader from "components/ActivityHeader"
import AppHeader from "components/AppHeader"
import CardAction from "components/CardAction"
import "assets/styles/Actions.css"
import img_fondateur from "assets/images/directeur2.jpeg"
import img_fondateur2 from "assets/images/directeur5.jpg"
import Global_header from "components/Global_header"
import img_action from "assets/images/equipe2.jpeg"
const Actions = ()=>{

    const Images = [
        `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
        "https://img.freepik.com/photos-gratuite/groupe-enfants-africains-apprenant-ensemble_23-2148892526.jpg",
        "https://img.freepik.com/photos-gratuite/petite-fille-souriante-bonheur-studio-portrait-sucette-douce_53876-146342.jpg",
        "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
        "https://img.freepik.com/photos-gratuite/enfants-africains-bras-croises_23-2148852698.jpg",
        "https://img.freepik.com/photos-gratuite/amis-heureux-etreignant-plan-moyen_23-2149479926.jpg",
        "https://img.freepik.com/photos-gratuite/gros-plan-smiley-filles-africaines-dehors_23-2148860429.jpg",
        "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
        `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
    ]

    return(
        <>
        <Global_header ref_link='/' img={img_action} title="Gouvernance" first_link="Accueil" second_link="Gouvernance > Directeur Exécutif" backgroundPosition="right 70% top 20%"/> 
       
                        
      
               <div className="row mt-4">

                {/* <div className="col-lg-6 col-md-6">

                    <div className="slide-img-content">
                        <img className="img-fluid" src= {img_fondateur}/>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12 mt-4">

                    <h2>MOT DU DIRECTEUR  EXECUTIF 

                        <div className="title_founder col-lg-8 ml-8">
                           
                        </div>
                    </h2>

                    <div>
                        <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil illo modi laboriosam ipsam, qui, cumque omnis necessitatibus voluptate error doloremque quis non? Debitis tenetur sed quam consequatur voluptate dolorem autem!</p>
                        <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil illo modi laboriosam ipsam, qui, cumque omnis necessitatibus voluptate error doloremque quis non? Debitis tenetur sed quam consequatur voluptate dolorem autem!</p>
                        <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil illo modi laboriosam ipsam, qui, cumque omnis necessitatibus voluptate error doloremque quis non? Debitis tenetur sed quam consequatur voluptate dolorem autem!</p>
                        <p className="sign_founder"> M. WILSON KOFFI BROU  </p> <br/> <br/>
                        <p className="sign_founder"> PRESIDENT DIRECTEUR EXECUTIF EICF </p>                   
                    </div>


                    
                </div> */}
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 mt-4">
                    
                <h2 className="mt-5 text-center" style={{marginLeft:"0%", marginBottom:"50px"}}>Biographie du directeur exécutif

                <div className="title_founder col-lg-7 ml-8">

                </div>
                </h2>
                <div   className="row">
                    <div className="col-md-6">
                    <div className="slide-img-content">
                        <img className="img-fluid" src= {img_fondateur2}/>
                    </div>
                    </div>
                    <div className="col-md-6 ">
                    <p> <b>BROU Koffi Wilson</b>, 42 ans, Economiste-Financier, Gestionnaire de projets de développement et Spécialiste en Management des Organisations, est marié et père de quatre enfants. </p>
                <p>Illustre « Caïman » du lycée classique d’Abidjan (2000-2002), il a intégré l’Université Félix Houphouët Boigny Abidjan Cocody où il a fait son 1er cycle universitaire, couronné par une Maitrise en Sciences économiques obtenue en 2006.</p>
                <p> Manager chevronné, il est titulaire d’un Master II en Sciences économiques, Option : Finance obtenu en 2008 l’Institut Universitaire d’Abidjan (IUA). </p>
                <p>M. Brou prépare actuellement un <b>Exécutif MBA à l’IAE de Paris (Sorbonne Business School) /IFG Executive Education (Institut Française de Gestion). </b></p>
                <p> Assoiffé de connaissances, il participe régulièrement à des formations continues dans le domaine de la gestion des projets de développement avec un accent particulier sur le Management des équipes et des organisations, la planification et le suivi-évaluation de projets. </p>
                    </div>
                    <div className="col-md-12 " style={{paddingLeft:"80px"}}>
                    <p> C’est en effet, ce qui justifie sa présence à la tête de l’ONG EICF en qualité de Directeur Exécutif depuis 15 ans. </p>
                <p> A cette fonction, ces qualités managériales et son sens élevé du leadership ont permis à l’Organisation de mobiliser plusieurs projets et partenariats majeurs dont <b>le projet SWEDD, le projet PMNDPE, le projet PEJEDEC, le projet USAID-MOMENTUM, le projet ARF (Ambassador Resilience Fund) financé par l’Ambassade des USA, le projet Réintégration du PNUD</b>,  avec des partenaires techniques et financiers comme<b> l’Etat de Côte d’Ivoire et les Ministères Techniques, la Banque Mondiale, l’Ambassade des USA, l’USAID, l’Union Européenne, le PNUD, le BCP-Emploi, l’AEJ (Agence Emploi Jeunes), l’UGP-PMNDPE, l’UCP-SWEDD, la GIZ IS, l’Association COGINTA, le FNLS (Fonds National de Lutte contre le Sida).</b></p>
                <p> Toujours assidu à la tâche, il considère le travail comme une prière car selon lui, <b>« l’homme se sanctifie dans le travail bien fait »</b> et n’a de cesse d’inculquer cette dynamique à ses collaborateurs.</p>
                <p>Engagé dans de nombreuses œuvres sociales et caritatives avec le Lions Club Abidjan Prestige dont il est <b>l’Immediate Past President (IPP)</b>, il œuvre en permanence à apporter le bien-être et le bonheur aux plus vulnérables.</p>
                <p>Son leadership au sein d’EICF a permis à l’Organisation de glaner plusieurs prix dont <b>Super Prix Bronze ADBA6 et Prix ADBA6</b> du Meilleur Promoteur des Œuvres Sociales et Humanitaires, délivrés par <b>AFRICA DUBAI BUSINESS AWARDS</b>, qui en font une organisation de référence dans le milieu humanitaire en Côte d’Ivoire.</p>
                    </div>
                </div>
              

                </div>
               </div>
         


        </>
    )
}

export default Actions