import "assets/styles/AppButton.css"
import "assets/styles/Myactu.css"
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import { Link } from "react-router-dom"
export default  function Myactualitedetail (props){

    return(
        <div className='col-lg-12 p-2'> 
            <Card>
                <div className="row p-5" style={{height:"100%"}}>
                    
                    <div className="col-lg-12 myactuimgdl d-flex justify-content-center" style={{height:"100%"}}>
                    <img src={props.img} className=" myactuimgdl img-fluid" />
                    </div>
                    <div className="col-lg-12" style={{height:"100%"}}>
                       
                        <CardBody>
                       <p className="text-center ">
                       <b  >
                            {props.title}
                            </b> <br/>
                       </p>
                          
                    
                            <p>
                                {props.mini_description}
                            </p>
                         </CardBody>
                        <CardFooter>
                            
                            {/* <button  className="donate-button" >
                                Voir plus
                            </button> */}
                            <Link className=" btn btn-warning text-center" to={props.to} >
                                   Voir plus
                                    </Link>
                        </CardFooter>
                    </div>
                </div>
               
               
               
            </Card>
        </div>
    )
}