import React, { useState, useEffect } from 'react';


import AppButton from 'components/AppButton';
import CardAction from 'components/CardAction8';
import cardImg  from 'assets/images/card_img.jpeg';
import cardImg2  from 'assets/images/cardImg2.jpg';
import cardImg3  from 'assets/images/card_img3.jpeg';
import OurFightCard from "components/OurFightCard";
import MySlider from 'components/MySlider';
import MySlidertop from 'components/MySlider1';
import AppHeader from 'components/AppHeader';
import 'assets/styles/Home.css';
import atelier  from 'assets/images/atelier3.jpeg';
import visite  from 'assets/images/visite_banque_mondiale.jpeg';
import nutrition  from 'assets/images/nutrition.jpeg';
import inspection  from 'assets/images/inspection_swedd.jpeg';
import mission  from 'assets/images/mission.jpeg';
import franc  from 'assets/images/franc.jpeg';
import nutrition_ban  from 'assets/images/nutrition.jpg';
import paix_et_cohesion  from 'assets/images/paix_et_cohesion sociale.jpg';
import temoignage  from 'assets/images/cafe.png';
import data_actu from 'components/data/actualite';
import sImg1  from 'assets/images/ong_eicf_price.jpg';
import sImg2  from 'assets/images/pjgouv.jpg';
import sImg3  from 'assets/images/banque_mondiale.jpg';
import sImg4 from 'assets/images/Partenariat.jpg'
import fImage from 'assets/images/8marsweb.jpg'
import fImage_mob from 'assets/images/8marsmob.jpg'
import sImg5 from 'assets/images/ei_n.jpg'
const Popup = ({ onClose }) => (
    <div style={{ 
      position: 'fixed', 
      top: '50%', 
      left: '50%', 
      width: '100%', 
      height: '100%', 
      transform: 'translate(-50%, -50%)', 
      padding: '20px', 
      background: '#fff', 
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
      zIndex: 999,
      
    }}>
       <div 
       className='justify-content-center align-items-center d-none d-sm-block'
       style={{ 
     
      width: '90%', 
      height: '90%', 
      marginLeft:'5%'
      
      
    }}>
<img src={fImage} alt="fight" className=" img-fluid rounded text-center d-flex img-center" /> <br/>
<button onClick={onClose} style={{right:"0%",background:"black",color:"white",zIndex:1000,bottom:10,position:"absolute", height:'50px',width:'150px'}}>X Revenir au site</button>
       </div>
       <div className='d-block d-sm-none'>
       <img src={fImage_mob} alt="fight" className=" img-fluid " /> 

       <button onClick={onClose} style={{right:"0%",background:"black",color:"white",zIndex:1000,top:10,position:"absolute", height:'50px',width:'150px'}}>X Revenir au site</button>
        </div>
      
      
    
    </div>
  );
  
const Home = ()=> {

    const slides = [
        { 
            firstContent: `Sommet de la Jeunesse pour la Paix et un Avenir  Durable ( Youth Peace Summit and Sustainable Future) organisé par l’Institut IPPDR ( Institute of Public Policy and Diplomacy Research) du 22 au 26 Juillet 2024 qui s’est tenu à Montpellier en France`, 
            secondContent: `Ce sommet avait réuni plusieurs jeunes, experts,  ONG et membres de la société civile  de plus de 50 pays du monde et avait pour objectif de promouvoir des stratégies de progrès et d’explorer davantage l’ODD 16 Paix, Justice et Institutions efficaces. 
EICF représentée par son Directeur Exécutif  a pris part à tous les ateliers et tables rondes sur les thématiques  ... `, 
            imageSrc: sImg5,
            to:'/actu/11',
            showButton:true
        },
        ,{ 
            firstContent: `Cérémonie de signature des Subventions organisée par l’Ambassade des USA en Côte d’Ivoire le jeudi 7 Décembre 2023`, 
            secondContent: "EICF est bénéficiaire du Fonds de Résilience de l’Ambassadeur pour financer  le projet intitulé :<< Engagement des Jeunes et des Femmes pour la prévention des crises, la stabilité sociale, la consolidation de la paix et la réduction de la violence communautaire dans la région du Bounkani >>... ", 
            imageSrc: sImg4,
            to:'/actu/10',
            showButton:true
        },

    { 
        firstContent: "Visite de supervision de la BANQUE MONDIALE dans le cadre du projet PMNDPE le 08 Mai 2023 à BOUNA", 
        secondContent: "Cette visite est le lieu de faire un point sur toutes les activités ménées par l'ONG EICF dans le cadre du projet PMNDPE", 
        imageSrc: visite ,
        to:'/actu/1',
        showButton:true
    },
        { 
            firstContent: "Atelier sur le reporting et la qualité des données organisé par EICF à l'hôtel SKY BLUE de Yamoussoukro le 08 Avril 2023.", 
            secondContent: "Cet atelier vise à renforcer les capacités des agents sur la qualité des données ", 
            imageSrc: atelier,
            to:'/actu/2',
            showButton:true
        },
       
        { 
            firstContent: "Sensibilisation communautaire en faveur de la Nutrition dans le département de PRIKRO.", 
            secondContent: "Cette sensibilisation à pour objectif d'aider les communautaire de  PRIKRO à amelirer la nutrition des enfants  ", 
            imageSrc: nutrition ,
            to:'/actu/3',
            showButton:true
        },
        { 
            firstContent: "Prix  du Meilleur Promoteur des Œuvres Sociales au  AFRICA DUBAI BUSINESS AWARDS  (ADBA) 6e ÉDITION 2023 .", 
            secondContent: "Du 22 au 23 Mai 2023 s’est tenue à Dubai la 6ème édition du prestigieux AFRICA DUBAI BUSINESS AWARDS, ..... ", 
            imageSrc: sImg1,
            to:'/actu/8',
            showButton:true
        },
        { 
            firstContent: "LANCEMENT DU PJ GOUV, UN PROGRAMME AMBITIEUX POUR L’ACCÉLÉRATION DE LA FORMATION, DE L’INSERTION PROFESSIONNELLE ET DE LA PROMOTION DE L’ENTREPRENEURIAT DES JEUNES.", 
            secondContent: "EICF, partenaire de mise en œuvre de L'AEJ (Agence Emploi Jeunes) et du BCP-Emploi, représentée par son Directeur Exécutif, a pris part au lancement du PJ GOUV aujourd'hui 15 Juin 2023 au palais de la culture de Treicheville. Un projet en cohérence avec la mise en œuvre de la politique publique du Gouvernement dénommée Programme Jeunesse du Gouvernement (PJ Gouv), d’un coût global de 1 118 milliards de FCFA et qui s’étend sur la période 2023-2025.", 
            imageSrc: sImg2,
            to:'/actu/7',
            showButton:false
        },
        { 
            firstContent: `EICF invitée par la Banque Mondiale  a pris part à la présentation du Rapport sur le Développement dans le Monde 2023 de la Banque mondiale, intitulé "Migrants, réfugiés et sociétés" qui a eu lieu à son siège à Cocody le Jeudi 15 Juin 2023.`, 
            secondContent: "Ce rapport offre un cadre solide pour l'élaboration de politiques en matière de migration et d'asile, en identifiant les approches unilatérales et plurilatérales ainsi que les responsabilités multilatérales nécessaires. Il constitue une occasion unique de débattre des enjeux migratoires et d'explorer des solutions innovantes. ", 
            imageSrc: sImg3,
            to:'/#',
            showButton:false
        },
      ];

      const slides1 = [

      { 
          firstContent: "Il y a quelques années, j'étais une jeune fille qui n'avait pas eu la chance d'aller à l'école. Dans mon village, l'éducation n'était pas considérée comme une priorité, surtout pour les filles. Les femmes étaient souvent cantonnées aux tâches ménagères et au travail dans les champs. Cependant, au fond de moi, je rêvais d'apprendre à lire, à écrire et à compter.Un jour, une ONG est venue dans notre village pour proposer des cours d'alphabétisation aux femmes et aux jeunes filles. C'était une opportunité que je ne pouvais pas laisser passer. J'ai immédiatement saisi cette occasion et je me suis inscrite.Au début, les cours étaient difficiles. J'étais en retard par rapport aux autres élèves qui avaient déjà bénéficié d'une éducation formelle. Mais j'ai persévéré, avec le soutien de mes professeurs et de mes camarades de classe. Petit à petit, j'ai commencé à reconnaître les lettres de l'alphabet, à former des mots et à écrire des phrases simples. C'était comme ouvrir un monde de possibilités.L'alphabétisation a changé ma vie. ", 
          secondContent: "", 
          image: temoignage 
      }  
        ];

        const anneeActuelle = new Date().getFullYear();
        let newan = anneeActuelle - 2008 



        const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    if (showPopup) {
      // Vous pouvez personnaliser le code ici pour afficher le popup comme vous le souhaitez.
      // Dans cet exemple, nous utilisons un simple `alert` pour illustrer le concept.
     

      // Après l'affichage du popup initial, mettez à jour l'état pour ne plus afficher le popup.
    //   setShowPopup(false);
    }
  }, [showPopup]);

  const closePopup = () => {
    // Cette fonction sera appelée lorsque l'utilisateur clique sur le bouton "Fermer" dans le popup.
    setShowPopup(false);
  };
  return (
    <>
        <div className='container-fluid mt-4' style={{overflowX:'hidden'}}>
            

        <MySlidertop slides={slides} />
            
        <div className="row justify-content-end header-card-main col-xs-12 col-sm-12 col-12 ">
           
            <div className="col-md-5 col-xs-12 col-sm-12 col-12 col-lg-5  hearder-card">
                <h2> Depuis {newan} ans notre action compte</h2>
                <div className="row">
                    <div className="col-md-6 col-xs-12 col-sm-12 col-12">
                        <p> Des Milliers de</p>
                        <p> personnes aidées en 2022  </p>
                    </div>
                    <div className="col-md-6 col-xs-12 col-sm-12 col-12">
                        <p>Une dizaines de  </p>    
                        <p> zones d'intervention </p>
                    </div>
                </div>
            </div>
        </div>
      </div>
{/*
      <div className='container'>
        <div className='row'>
        <div class="col-lg-4"><div class="sigma_portfolio-item"><img src="	https://slidesigma.nyc/templatemonster/react/martalaa/assets/img/ministries/16.jpg" alt="Music Ministry"/><div class="sigma_portfolio-item-content"><div class="sigma_portfolio-item-content-inner"><h5> <a href="/templatemonster/react/martalaa/ministry-details/1">Music Ministry</a> </h5><p class="blockquote bg-transparent">Lorem Ipsum is simply dummy text of the printing and typesetting indus</p></div><a href="/templatemonster/react/martalaa/ministry-details/1"><i class="fal fa-plus"></i></a></div></div></div>
        </div>
  </div>*/}

      <div className="home-card-actions">
      <div className="card-sections container-fluid">
        <center>
            <h1 className="live_terrain">En direct du terrain .</h1>
        </center>
       
        <center>
            <div className="d-flex flex-wrap jusitfy-content-between align-items-center ">
                <center>
     
                 <div className="row">
                   {data_actu?.map((elt,index)=>
                    ((index<6)? <CardAction 
                        title={elt.titre}
                        img={elt.image}
                        text={elt.mini_description}
                        to={"/actu/"+elt.id}
                     />:null)
                   )}
                     {/* <CardAction 
                     title="Visite banque mondiale "
                     img={visite}
                     text=" Visite de supervision de la BANQUE MONDIALE dans le cadre du projet PMNDPE le 08 Mai 2023 à BOUNA"
                  />
     
              <CardAction 
                     title="Atelier sur le reporting "
                     img={atelier}
                     text="Atelier sur le reporting et la qualité des données organisé par EICF à l'hôtel SKY BLUE de Yamoussoukro le 08 Avril 2023."
                  />
              <CardAction 
                     title="Sensibilisation nutrition"
                     img={nutrition}
                     text="Sensibilisation communautaire en faveur de la Nutrition dans le département de PRIKRO."
                  />
     
                  <CardAction 
                  title="mission de l'inspection générale des finances"
                  img={inspection}
                  text="Dans le cadre du projet SWEDD ÉDUCATION, une mission de l'inspection générale des finances s'est rendue dans les régions du Bélier, Iffou et Gontougo où EICF conduit ce projet. Du 08 au 17 mai l'équipe de l'IGF a voulu s'assurer de la bonne exécution du cahier de charge conféré à notre organisation."
               />
     
               <CardAction 
               title=" Mission de la Directrice des Opérations de la Banque mondiale"
               img={mission}
               text="Compte rendu en images et vidéos de la Mission de la Directrice des Opérations de la Banque mondiale sur le Site de BANIA, tenue par L'ONG EICF."
            />
     
     
            <CardAction 
            title="J-1:Travaux d'entretien du FRANC"
            img={franc}
            text="Le directeur exécutif est envahi par la marmaille du FRANC"
         />
      */}
                 </div>
     
     
     
     
                </center>
               
     
     
                 
                 
                
             </div>
             <div className="row jusitfy-content-center align-items-center text-center mt-3 mt-md-5">
                 <div className="col-md-12 ">
                     <AppButton title="Toutes les actualiés" to="/actualite" />
                 </div>
                 {/* <div className="col-md-4">
                     <AppButton title="Actu des réseaux" to="" />
                 </div> */}
     
             </div>
        </center>
        

      </div>
      </div>

      <div className="our-fights-section container">
        <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-9">
                <h1 className="fw-bolder">
                    nos actions contre la pauvrété
                </h1>
                <p className="mt-2">
                    Plus de 100.000 communautés touchées par nos actions en milieu rural  
                </p>
            </div>
        </div>
        <div className="d-flex flex-wrap jusitfy-content-between align-items-center">
                <OurFightCard title="Santé publique et Nutrition de la petite enfance"  imageSrc={nutrition_ban} to="/actions-details/3"/>
                <OurFightCard title="Paix et cohésion sociale" imageSrc={paix_et_cohesion} to="/actions-details/7" />
                <OurFightCard title="Éducation-Formation et Développement de la Petite Enfance (DPE)"  imageSrc={franc} to="/actions-details/4" />
            
        </div>
        <div className="text-center mx-auto mt-3 mt-md-5">
            <AppButton title="Découvrir tous les combats" to="/actions"/>
        </div>
      </div>

      <div className="join-us-section">
        <div className="join-us-content container">
            <div className="row align-items-center">
                <div className="col-md-6 col-xs-12">
                    <h2 className="fw-bolder text-white " >
                        Agir avec <span style={{color: '#76b72a'}}>EICF</span> 
                    </h2>
                    <h4 className="mt-3 fw-bolder">
                        ENSEMBLE IMAGINONS ET CONSTRUISONS LE FUTUR (EICF) est une Organisation Non Gouvernementale (ONG) crée le 11 Février 2008 et qui œuvre pour l’atteinte des  ODD.
                    </h4>
                    <p className="fw-bolder mt-5">
                        Notre objectif est de contribuer à l’atteinte des Objectifs De Développement Durable (ODD) en apportant le mieux être aux populations les plus vulnérables en Côte d’Ivoire et partout en Afrique.
                    </p>
                </div>
            </div>
        </div>
        <div className="join-us-actions gap-2 d-flex justify-content-around align-items-center container">
            <div className="text-center ">
                <h3>Nous rejoindre</h3>
                <div className="d-flex">
                   
                    <AppButton title="JE M'ABONNE A NEWSLETTERS" />
                </div>
            </div>
            <div className="text-center  "  >
                <h3>Agir ensemble</h3>
                <div className="d-flex mt-2">
                    {/* <AppButton title="JE SOUTIENS UNE COMPAGNIE" /> */}
                    <AppButton title="JE FAIS UN DON" to="/mon-don"/>
                </div>
            </div>
        </div>
      </div>

      <div className="mt-5 pt-5 pb-5 mb-5 container">
        <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-9">
                <h1 className="fw-bolder">
                    Nos victoires en témoignages !
                </h1>
                <p className="mt-2">
                    Decouvrez l'impact des actions de EICF
                </p>
            </div>
        </div>
        <div className="row">
            <MySlider slides={slides1} />
        </div>
      </div>
      {showPopup && <Popup onClose={closePopup} />}
    </>
  );
}

export default Home;
