import "assets/styles/AppButton.css"
import "assets/styles/Myactu.css"
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import { Link } from "react-router-dom"
export default  function Myactualite (props){

    return(
        <div className='col-lg-12 pb-5'> 
            <Card>
                <div className="row p-3">
                    <div className="col-lg-12">
                        <CardHeader className="h4 text-center">
                        {props.title}
                        </CardHeader>
                    </div>
                    <div className="col-lg-6 myactuimg text-center p-auto">
                    <img src={props.img} className=" myactuimg img-fluid mx-auto" />
                    </div>
                    <div className="col-lg-6">
                        <CardBody>
                            <div className="h5">
                            {props.date}
                            </div>
                            
                         </CardBody>
                        <CardBody>
                            <div className="P ">
                            {props.description}
                            </div>
                            
                         </CardBody>
                        <CardFooter>
                            
                            {/* <button  className="donate-button" >
                                Voir plus
                            </button> */}
                            <Link className=" btn btn-warning " to={props.to} >
                                   Voir plus
                                    </Link>
                        </CardFooter>
                    </div>
                </div>
               
               
               
            </Card>
        </div>
    )
}