
import logo from "assets/images/logo_ong_eicf.jpg"
import logo1 from "assets/images/partenaire/la-banque-mondiale-logo.png"
import logo2 from "assets/images/partenaire/pmndpe.png"
import logo3 from "assets/images/partenaire/swedd.jpg"
import logo4 from "assets/images/partenaire/ministere_sante.jpg"
import logo5 from "assets/images/partenaire/agence_emploi.png"
import logo6 from "assets/images/partenaire/pnud.png"
import logo7 from "assets/images/partenaire/GIZ.jpg"
import DonateButton from "./DonateButton"
import "assets/styles/Footer.css"
import { Link } from "react-router-dom"
import  {NavButton } from './AppButton';
import Slider from "react-slick";
import React, { useState, useEffect } from 'react';
const slides = [{
    
        logo:logo1 
    
},{
    
    logo:logo2

},{
    
    logo:logo3

},{
    
    logo:logo4

},{
    
    logo:logo5

},{
    
    logo:logo6

},{
    
    logo:logo7

}]
const Slide = ({logo,key}) => {
    return (
   
 
      <div className="col-lg-11 col-md-11">
            <img src={logo} alt="ONG EICF" className="img-fluid mx-auto" style={{height:"150px"}} />
          
      </div>
  
  
  
  
  
    );
  }

  const MySlider = (props) => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    };
  
    const slides = props.slides.map((logo, index) => {
      return (
        <Slide key={index} {...logo} />
      );
    });
  
    return (
      <Slider {...settings}>
        {slides}
      </Slider>
    );
  }
   
export default function Footer(){
  
    
    const [afficherElement, setAfficherElement] = useState(false);
    
    const send_from =()=>{
        setAfficherElement(true)
        setTimeout(() => {
            setAfficherElement(false);
          }, 6000)
    }

    return(
        <footer className="footer mt-5 container-fluid">
            <div className="container mb-5">
                <div className="row  ">
                    <div className="col-lg-12 mb-5 text-center">
                            <h2>Nos partenaires</h2>
                    </div>
                    <MySlider slides={slides} className="justify-content-center d-flex"/>
                    {/* <div className="col-lg-3">
                        <img src={logo} alt="ONG EICF" className="img-fluid mx-auto" style={{width:"8rem"}} />
                    </div>
                    <div className="col-lg-3">
                        <img src={logo} alt="ONG EICF" className="img-fluid mx-auto" style={{width:"8rem"}} />
                    </div> */}
                </div>


            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                            <div className="d-flex  align-items-center">
                                <div className="col-md-4 text-center align-items-center ">
                                    <img src={logo} alt="ONG EICF" className="img-fluid mx-auto" style={{width:"8rem"}} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 my-3">
                                    <h6 className="text-uppercase fw-bolder text-center mt-3" >
                                    ENSEMBLE IMAGINONS ET CONSTRUISONS LE FUTUR (EICF) 
                                    </h6>
                                    <p className="text-justify">
                                    est une Organisation Non Gouvernementale (ONG) créée le 11 Février 2008 et qui œuvre pour l’atteinte des ODD.
                                    Son objectif est de contribuer à l’atteinte des Objectifs De Développement Durable (ODD) en apportant le mieux être aux populations les plus vulnérables en Côte d’Ivoire et partout en Afrique
                                    </p>
                                    <div className="text-center mt-3">
                                    <NavButton title="JE FAIS UN DON"route="/mon-don" />
                                    </div>
                                </div>
                                <div className="col-md-4 my-3 contacts">
                                    <h6 className="text-uppercase fw-bolder text-center mt-3" >
                                       contacts
                                    </h6>
                                    <div className="text-center mt-3 ">
                                        <p>
                                        27 BP 219 Abidjan 27
                                        </p>
                                        <p className="mt-5">
                                            <span>Phone : </span> :(+225) 27 22 53 46 40 / 27 22 01 65 24 / 07 66 91 76  / 03 16 67 84 / 07 07 08 12 85
                                        </p>
                                        <p>
                                            <span>Email : </span> ongeicf@gmail.com/ info@ongeicf.com 
                                        </p>
                                        <p>
                                            <span>Web : </span> www.ongeicf.org / ong eicf 
                                        </p>
                                        
                                    </div>
                                </div>
                                <div className="col-md-4 my-3 links">
                                    <h6 className="text-uppercase fw-bolder text-center mt-3" >
                                        Liens utiles
                                    </h6>
                                    <div className="text-center mt-3 who-us">
                                        
                                        <ul className="">
                                            <li><Link className="footer-field" to={"/who-us"}>Qui sommes nous ?</Link> </li>
                                            <li><Link className="footer-field" to={"/actions"}>Nos Actions</Link> </li>
                                            <li><Link className="footer-field" to={"/projects"}>Nos Projets</Link> </li>
                                            {/* <li><Link className="footer-field" to={"#"}>Gouvernance</Link> </li> */}
                                            <li><Link className="footer-field" to={"/actualite"}>Actualité</Link> </li>
                                            <li> <Link className="footer-field" to={"/contact"}>Nous contacter</Link> </li>
                                            
                                            
                                            
                                           
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 text-center mb-3">
                                Designed and builded by <Link className="created_footer" to={"https://skyvisionafrica.com"}>Skyvision Africa</Link> 
                                </div>
                            </div>
                    </div>
                    <div className="col-md-4 informations mt-3">
                        <h2 className="fw-bolder" >
                            S'informer, <br/> c'est déjà s'engager
                        </h2>
                        <div className="mt-5">
                        {afficherElement && (
                            <div>
                            <p style={{color:"red", fontWeight:"bold"}}>Email enregistré avec succès</p>
                            </div>
                        )}
                            
                            <label className="fw-bolder" >Je m'abonne à EICF </label>
                            <div className="inputs mt-2">
                                <input className="" placeholder="Votre adresse Email" />
                                <button  className="w-100 " onClick={send_from}>envoyer</button>
                            </div>
                        </div>

                    </div>
                 
                </div>
                
            </div>
        </footer>
    )
}