import "assets/styles/Global_header.css"
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import { Link } from "react-router-dom"

export default  function Global_header (props){


    return(
        <div className='global_header_2 col-lg-12 pb-5' > 
       
            <div className="global_header_zone_2"  style={{
            // background: `linear-gradient(90deg, #e6b9048e 0%, #75b72a7c 100%), url(${ props.img })`,
            background: `linear-gradient(90deg, #090604 0%, #bdbc9869 100%), url(${ props.img })`,
            backgroundSize: 'cover',
        backgroundPosition: "right 85% top 10%",
        backgroundRepeat: 'no-repeat',
          }}>
               
               <div className="row">
                    <div className="col-lg-5">
                    <div>
                    <div className="global_header_2_1 text-center">
                    {props.title}
                    </div>
                    <div className="global_header_2_2 text-center mt-5">
                        {props.description}
                    </div>
                </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}