
import { Link } from "react-router-dom";
import cardImg from "assets/images/card_img.jpeg"
import { useNavigate } from "react-router-dom";
import "assets/styles/CardAction.css"
import { Button } from "@chakra-ui/react";

const CardAction =  ({text,title,link,img})=>{
    const handleDownload = () => {
        
        // Créez un élément 'a' pour le téléchargement
        const a = document.createElement('a');
        // a.href = link;
        // a.download = title+'.pdf'; // Définissez le nom du fichier
    
        // // Simulez un clic sur l'élément 'a' pour déclencher le téléchargement
        // a.click();
    
        // Libérez les ressources de l'objet URL
        a.href = link;
        a.download = title+'.pdf'
        a.target = '_blank'
        a.click();
       
        URL.revokeObjectURL(link);
      };
     
    const navigate = useNavigate();


    const showActionDetails = ()=>{
        window.open(!!link?link:"/actions-details",'_blank')
    }

    return(
        <>
        <div 
            className="card-action col-lg-12 col-md-12 col-xs-12 col-sm-12 col-12 shadow mt-4 mx-md-12 rounded-4"
            onClick={handleDownload}>
            <div>
                <div className="card-action-image-container col-lg-12 col-md-12 col-xs-12 col-sm-12 d-flex justify-content-center">
                    <img src={img} alt="ONG EICF" style={{backgroundSize:"cover"}} className="img-fluid   rounded-top" />
                </div>
                <div className="card-action-body1 px-3 text-center pt-3">

                    <h4 className="fw-bolder pt-2" > {title} </h4>
                    
                    <h6 className="pt-4 " > {text} </h6>
                   
                    {/* <Link className="card-link" to={!!link?link:"/actions-details"}> Télécharger </Link> */}
                    <button className="card-link" onClick={handleDownload}> Télécharger</button>
                   
                </div>
       
            </div>
        </div>
        </>
    )
}

export default CardAction