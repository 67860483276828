import rapport_1 from "assets/bulletin/Bulletin_information_trimestriel_n2.pdf"
import rapport_2 from "assets/bulletin/EICF_MARS_20223.pdf"

const data_bulletin = [{
    id:1,
    titre:"Bulletin information trimestriel N2",
    pdf:rapport_1
},{
    id:2,
    titre:"EICF Bulletin Mars 2023",
    pdf:rapport_2
}



]

export default data_bulletin;