import 'assets/styles/Contact.css';
import { ListItem,OrderedList,Select,Card, CardHeader, CardBody, CardFooter,Button,ButtonGroup,Textarea,Image}  from '@chakra-ui/react'
import Myemploi from 'components/Emploi';
import Img from "assets/images/recrutement.png"
import Global_header from "components/Global_header"
import data_emploi from 'components/data/emploi';
import img_action from "assets/images/homme-assis-pour-entretien-embauche-au-bureau-ses-employeurs.jpg"
const Emploi =()=>{
 
    return (
        <div>
            <Global_header ref_link='/' img={img_action} title="Offre d'emploi " first_link="Accueil" second_link="Offre d'emploi" backgroundPosition="right 85% top 70%"/> 
            <div className='container-fluid   mt-5'>
                
                <div className='row '>
                        <div className='col-lg-4 '>
                        
                        <img src={Img} alt="ONG EICF" className="img-fluid mx-auto  rounded-top" />
                        </div>
                        <div className='col-lg-8'>
                            <div className='row'>
                            {
                                (data_emploi.length!=0)?
                                    data_emploi?.map(elt=>(
                        <Myemploi title={elt.titre} date={elt.date_expiration} description={elt.description} pdf={elt.pdf}/>
                        )):(
                            <div className='col-lg-12 text-center mt-5'>
                                <h2> Pas d'offre d'emploi disponible actuellement</h2>
                            </div>

                        )
                                }
                            
                            
                            </div>
                        </div>
                      
                </div>
            </div>
       </div>
    )
}
export default Emploi