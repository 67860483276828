
import { List,  ListItem,  ListIcon } from '@chakra-ui/react'
import { FaMapMarkerAlt } from 'react-icons/fa';


const InterventionsProjet =({contry,area})=>{
    return(
        <div className="interventions-contries-container ">
            <h2 className="h2 fw-bolder">
                {contry}
            </h2>
            <div className="divider"></div>
            <div className="interventions-contries-content">
                <List spacing={3} className="interventions-contries-list">
                
                    {area.map((elt )=>{
                        return (

                            <ListItem>
                            <ListIcon as={FaMapMarkerAlt} color='orange.500' />
                        {elt}
                        </ListItem>
                        )
                         
                    })}
                   
                
                </List>
            </div>
        </div>
    )
}

export default InterventionsProjet