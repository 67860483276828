
import { Link } from "react-router-dom";
import cardImg from "assets/images/card_img.jpeg"
import { useNavigate } from "react-router-dom";
import "assets/styles/CardAction.css"
import { Button } from "@chakra-ui/react";

const CardAction =  ({text,category,title,link,img})=>{
    const navigate = useNavigate();


    const showActionDetails = ()=>{
        navigate(!!link?link:"/projects")
    }

    return(
        <>
        <div 
            className="card-action shadow mt-4 rounded-4 col-lg-4"
            onClick={showActionDetails}>
            <div>
                <div className="card-action-image-container">
                    <img src={img} alt="ONG EICF" className="img-fluid  rounded-top " style={{width:"100%"}}/>
                </div>
                <div className="card-action-body px-3 text-center pt-3 pb-3">

                    <h6> {category} </h6>
                    <h4 className="fw-bolder pt-2" > {title} </h4>
                    
                    <p className="pt-4 " > {text} </p>
                   
                    {/*<Link className="card-link" to={!!link?link:"/actions-details"}> Comprendre </Link>*/}
                    <Button 
                            style={{color:'var(--color-primary-yellow)'}}
                            colorScheme='var(--color-primary-yellow)' 
                            className="rounded-pill w-100 py-4 "
                            variant='outline'>
                            Détail 
                        </Button>
                </div>
                
            </div>
        </div>
        </>
    )
}

export default CardAction