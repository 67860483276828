

import "assets/styles/AppButton.css"
import { Link, NavLink } from 'react-router-dom';
import {
   
    Button,
  
  } from '@chakra-ui/react'
export default  function AppButton ({title,to}){


    return(
      
          
          <Link to={to} >
              <Button  className="app-button mx-md-0 mx-1" >
            {title}
        </Button>
        </Link>
        
    )
}
export   function NavButton ({title,route}){


    return(
        <Button  className="nav-button mx-md-0 mx-1"  as={Link} variant='ghost' to={route}>
            {title}
        </Button>
    )
}
export   function NavButtonmodel ({title,route,closemodal}){


    return(
        <Button  className="nav-button mx-md-0 mx-1"  as={Link} variant='ghost' to={route} onClick={closemodal}>
            {title}
        </Button>
    )
}

export  function AppButtonRapport ({icon,title,route}){


    return(
        <Button  className="app-button-rapport d-flex justify-content-between align-items-center gap-1 " style={{marginLeft:10}} as={Link} variant='ghost' to={route} >
           {icon} {title}
        </Button>
    )
}
export  function AppButtonRapportmodel ({icon,title,route,closemodal}){


    return(
        <Button  className="app-button-rapport d-flex justify-content-between align-items-center gap-1 " style={{marginLeft:10}} as={Link} variant='ghost' to={route} onClick={closemodal}>
           {icon} {title}
        </Button>
    )
}

export  function AppButtonRapportIcon ({icon,title,route}){


    return(
      
         <Button  className="nav-button mx-md-0 mx-1 fx-2"  as={Link} variant='ghost' to={route}>
         {icon}{title}
     </Button>
    )
}