import "assets/styles/AppButton.css"
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
export default  function Myemploi (props){


    return(
        <div className='col-lg-12 pb-5'> 
            <Card>
                <CardHeader className="h2">
                    {props.title}
                </CardHeader>
                <CardBody>
                    <div className="P ">
                    {props.description}
                    </div>
                    <div className="h4 text-center">
                    Disponibles jusqu'au {props.date}
                    </div>
                </CardBody>
                <CardFooter>
                    <button  className="donate-button" >
                        Télecharger l'offre
                    </button>
                </CardFooter>
            </Card>
        </div>
    )
}