import React from 'react';
import Slider from "react-slick";
import DonateButton from './DonateButton';
import "assets/styles/Slide.css"
import Projet_card from './Otherprojetslide';
const { default: AppButton } = require("./AppButton")

const Slide = ({title, text,image,to,showButton=true,key}) => {
  return (
    <div className='col-lg-4'>
          <Projet_card title={title} text={text} image={image} to={to}/>
    </div>
  




  );
}

const MySliderpro = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const slides = props.slides.map((slide, index) => {
    return (
      <Slide key={index} {...slide} />
    );
  });

  return (
    <Slider {...settings}>
      {slides}
    </Slider>
  );
}

export default MySliderpro;
