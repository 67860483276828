import { useRef } from 'react';
import 'assets/styles/Mondon.css';
import { Input,Stack,InputGroup,InputLeftElement,InputRightElement,Icon,Checkbox,Select,Button,ButtonGroup, Link }  from '@chakra-ui/react'
import Global_header from 'components/Global_header';
import mon_don from "assets/images/eicf_2.jpg"
import paypal from "assets/images/Paypal.png"
import visa from "assets/images/visa.png"
import mastercard from "assets/images/mastercard.png"
import React, { useState, useEffect } from 'react';

const Mondon =()=>{
    const [afficherElement, setAfficherElement] = useState(false);
    
    const send_from =()=>{
        setAfficherElement(true)
        setTimeout(() => {
            setAfficherElement(false);
          }, 6000)
    }
    return (
    <div>
        <Global_header ref_link='/' img={mon_don} title="Je fais un don" first_link="Accueil" second_link="Faire un don"/>
        <div className='container-fluid '>
            <div className='row'>
                <div className='col-lg-6 offset-lg-3'>
                  <div className='card'>
                           <div className='card-header text-center don_header'>
                                Mon don
                           </div>
                           <div className='card-body'> 
                                <div className='row'>
                                    <div className='d-flex justify-content-center'>
                                        <img src={paypal} className='img-fluid' style={{height:"100px"}}/>
                                        <img src={visa} className='img-fluid' style={{height:"100px"}}/>
                                        <img src={mastercard} className='img-fluid' style={{height:"100px"}}/>
                                    </div>
                                    <a target="_blank" href='https://www.paypal.com/donate/?hosted_button_id=46GMHQ4ALBXCJ' className='col-4 offset-4 don_button text-center'>
                                    Faire un don
                                    </a>

                                </div>
                           </div>
                    </div>
                </div>
            </div>
{/*            
           <div className='row'>
                <div className='col-lg-6'>
                    <div className='card'>
                           <div className='card-header text-center don_header'>
                                Mon don
                           </div>
                           <div className='card-body'> 
                                <div className='row'>
                                    <div className='col-9'>
                                    <Input placeholder="Montant" />
                                    </div>
                                    <div className='col-3 don_current text-center'>
                                            Fcfa
                                    </div>
                                </div>
                           </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='card'>
                           <div className='card-header text-center don_header'>
                                Mes coordonées
                           </div>
                           <div className='card-body'> 
                           <Stack spacing={4}>
                                <InputGroup>
                                    <InputLeftElement children={<Icon name="email" color="gray.300" />} />
                                    <Input type="email" placeholder="Votre email" />
                                </InputGroup>
                                <Checkbox defaultIsChecked>Je fais un don au nom d'une organisation ou d'une société</Checkbox>
                                <Select variant="flushed" placeholder="Civilité*">
                                    <option value="option1">M</option>
                                    <option value="option2">Mme</option>
                                    <option value="option3">Mlle</option>
                                </Select>
                                <Input  placeholder="Votre Nom" />
                                <Input  placeholder="Votre Prenom" />
                                <Input  placeholder="Votre numéro de télephone" />
                                <Input  placeholder="Votre Ville" />
                                <Input  placeholder="Votre Pays" />
   
                            </Stack>
                           </div>
                           <div className='card-body'>
                           {afficherElement && (
                            <div>
                            <p style={{color:"red", fontWeight:"bold"}}>Votre demande a bien été prise en compte!!!</p>
                            </div>
                        )}
                           <ButtonGroup spacing={4}>
                              
                                <Button className='don_button' onClick={send_from}>
                                    Faire un don
                                </Button>
                            </ButtonGroup>
                           </div>
                    </div>
                </div>
                
           </div> */}
        </div>
        </div>
    )
}

export default Mondon