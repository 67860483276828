
import {createBrowserRouter,RouterProvider} from "react-router-dom";
import Layout from "components/Layout";
import Home from 'views/Home';
import Actions from "views/Actions";
import DetailsActions from "views/DetailsActions";
import Projects from "views/Projects";
import DetailsProjects from "views/DetailsProjects";
import WhoUs from "views/WhoUs";
import ErrorPage from "views/ErrorPage";
import Fondateur from "views/Fondateur";
import Directeur from "views/Directeur";
import Equipe from "views/Equipe";
import Rapport from "views/Rapport";
import Rapport_semestriel from "views/Rapport_semestriel";
import Nos_action from "views/Nos_actions";
import Mondon from 'views/Mondon';
import Contact from 'views/Contact';
import Emploi from "views/Emploi";
import Offre from "views/Offre";
import Actualite from "views/Actualite";
import DetailActu from "views/DatailActualite";
import Ousommesnous from "views/Ousommesnous";

// Fichier style de la libairie React-Slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'assets/styles/App.css';






function App() {

  //Configurations des routes de l'application
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <ErrorPage />,
      children:[
        {
          path: "/",
          element: <Home />
        },
        {
          path: "/actions",
          element: <Nos_action />
        },
        {
          path: "/actions-details/:id",
          element: <DetailsActions />
        },
        {
          path: "/projects",
          element: <Projects />
        },
        {
          path: "/projects-details/:id",
          element: <DetailsProjects />
        },
        {
          path: "/who-us",
          element: <WhoUs />
        },
        {
          path:"/fondateur",
          element:<Fondateur/>
        },

        {
          path:"/directeur",
          element:<Directeur/>
        },

        {
          path:"/equipe",
          element:<Equipe/>
        },

        {
          path:"/rapport",
          element:<Rapport/>
        },
        {
          path:"/rapport_trimestriel",
          element:<Rapport_semestriel/>
        },

        {
          path: "/mon-don",
          element: <Mondon />
        },
        {
          path: "/contact",
          element: <Contact />
        },
         {
          path: "/emploi",
          element: <Emploi />
        },
        {
          path: "/offre",
          element: <Offre />
        },
        {
          path: "/actualite",
          element: <Actualite />
        },
        {
          path: "/actu/:id",
          element: <DetailActu />
        },
        {
          path:"/where",
          element: <Ousommesnous/>
        }

        
        
      ]
    }
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
