import ActivityHeader from "components/ActivityHeader";
import AppHeader from "components/AppHeader";
import CardAction from "components/CardAction2";
import "assets/styles/Actions.css";

import pdf from "assets/images/pdf.png";
import Global_header from "components/Global_header";

import rapport_1 from "assets/bulletin/Bulletin_information_trimestriel_n2.pdf"
import rapport_2 from "assets/bulletin/EICF_MARS_20223.pdf"
import data_bulletin from "components/data/bulletin";
import img_nutri from "assets/images/inspection_swedd.jpeg"
const Rapport_semestriel = () => {
  const Images = [
    `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
    "https://img.freepik.com/photos-gratuite/groupe-enfants-africains-apprenant-ensemble_23-2148892526.jpg",
    "https://img.freepik.com/photos-gratuite/petite-fille-souriante-bonheur-studio-portrait-sucette-douce_53876-146342.jpg",
    "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
    "https://img.freepik.com/photos-gratuite/enfants-africains-bras-croises_23-2148852698.jpg",
    "https://img.freepik.com/photos-gratuite/amis-heureux-etreignant-plan-moyen_23-2149479926.jpg",
    "https://img.freepik.com/photos-gratuite/gros-plan-smiley-filles-africaines-dehors_23-2148860429.jpg",
    "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
    `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
  ];

  return (
    <>
     <Global_header ref_link='/' img={img_nutri} title="Bulletins Trimestriels EICF" first_link="Accueil" second_link="Bulletins Trimestriels EICF"/>
     

      <div className="row mt-4">
        <div class="col-lg-12 col-md-12 col-xs-12 col-">
          <center>
            <div className="title_founder3"></div>
          </center>
        </div>
      </div>

      <div className="row mt-4">
      {
            data_bulletin?.map(elt=>(
              <div className="col-lg-4 col-md-4 col-12 col-xs-12 col-sm-12">
                     <CardAction
            title={elt.titre}
            img={pdf}
            text=""
            link={elt.pdf}
          />
          
            </div>
             
            ))
          }
        
      
      </div>
    </>
  );
};

export default Rapport_semestriel;
