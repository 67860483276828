import 'assets/styles/Contact.css';
import { Input,Stack,InputGroup,InputLeftElement,InputRightElement,Icon,Checkbox,Select,Button,ButtonGroup,Textarea,Image}  from '@chakra-ui/react'
import Global_header from 'components/Global_header';
import React, { useState, useEffect } from 'react';


import paix_cohesion from "assets/images/contact.jpg";
const Contact =()=>{
    const [afficherElement, setAfficherElement] = useState(false);
    
    const send_from =()=>{
        setAfficherElement(true)
        setTimeout(() => {
            setAfficherElement(false);
          }, 6000)
    }
    return (
        <div>
            <Global_header ref_link='/' img={paix_cohesion} title="Nous contacter" first_link="Accueil" second_link="Nous contacter" backgroundPosition="right 85% top 50%"/>
            
            <div className='container-fluid  contact_zone mt-5'>
                
                <div className='row '>
                        <div className='col-lg-6 mt-5 mb-5 text-center'>
                            <h1 className='text-white'>Contactez-nous !</h1>
                            <h5 className='text-white'>Nos équipes sont disponibles pour répondre à toutes vos interrogations.  </h5>
                        </div>
                        <div className='col-lg-6 mt-5 mb-5 pt-4 pb-4  contact_field'>
                        <Stack spacing={4}>
                                        <InputGroup>
                                            <InputLeftElement children={<Icon name="email" color="gray.300" />} />
                                            <Input type="email" placeholder="Votre email" />
                                        </InputGroup>
                                    
                                        <Select variant="flushed" placeholder="-Objet de votre demande-">
                                            <option value="option1">Je souhaite joindre le service donateurs</option>
                                            <option value="option2">Je souhaite mettre en place un partenariat avec EICF</option>
                                            <option value="option3">Je souhaite contacter le service des ressources humaines</option>
                                            <option value="option4">Je souhaite obtenir des informations sur les legs</option>
                                        </Select>
                                        <Input  placeholder="Votre Nom" />
                                        <Input  placeholder="Votre Prenom" />
                                        <Input  placeholder="Votre numéro de télephone" />
                                        <Textarea placeholder="Précisez votre demande" />
                            </Stack>
                            {afficherElement && (
                            <div>
                            <p style={{color:"red", fontWeight:"bold"}}>Votre demande a bien été prise en compte!!!</p>
                            </div>
                        )}
                            <ButtonGroup spacing={4}>
                            
                                        <Button className='don_button mt-2' onClick={send_from}>
                                            Valider la demande
                                        </Button>
                                    </ButtonGroup>
                        </div>
                </div>
            </div>
       </div>
    )
}

export default Contact