import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


export default function Layout(){

    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0); // Réinitialiser la position de la barre de défilement à zéro
    }, [location]);
    return(
        <>
            <Navbar />
            <main className="container-fluid" >
               <Outlet />
            </main>
            <Footer/>
        </>
    )
}