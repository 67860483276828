import 'assets/styles/Contact.css';
import { ListItem,OrderedList,Select,Card, CardHeader, CardBody, CardFooter,Button,ButtonGroup,Textarea,Image}  from '@chakra-ui/react'
import Myemploi from 'components/Emploi';
import Myactualite from 'components/Myactualite';

import Img from "assets/images/news.jpg"
import paix_et_cohesion  from 'assets/images/paix_et_cohesion sociale.jpg';
import Global_header from 'components/Global_header';
import data_actu from 'components/data/actualite';
import img_action from "assets/images/mission.jpeg"
const Actualite =()=>{
 
    return (
        <div>
            <Global_header ref_link='/' img={img_action} title="Actualités" first_link="Accueil" second_link="Actualités"/>
            <div className='container-fluid   mt-5'>
                
                <div className='row '>
                        
                        <div className='col-lg-8 offset-lg-2'>
                            <div className='row'>
                            {data_actu.map(elt=>(

<Myactualite img={elt.image } title={elt.titre}  date={elt.date} description={elt.mini_description} to={"/actu/"+elt.id}/>
                            ))
                            
                            
                            }
                           
                            
                            
                            </div>
                        </div>
                        {/* <div className='col-lg-4 ' style={{position:"fixed",right:"0px",bottom:'10px',zIndex:'100'}}>
                        
                        <img src={Img} alt="ONG EICF" className="img-fluid mx-auto  rounded-top" />
                        </div> */}
                      
                </div>
            </div>
       </div>
    )
}

export default Actualite