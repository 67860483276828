import ActivityHeader from "components/ActivityHeader"
import AppHeader from "components/AppHeader"
import CardAction from "components/CardAction"
import "assets/styles/Actions.css"
import "assets/styles/map.css"
import Global_header from "components/Global_header"

import img_nutri from "assets/images/atelier5.jpeg"
// import MapChart from "components/Map"
// import IvoryCoastMap from "components/Mapivory"

// import Mapivory from "components/Mapivory2"
// import "components/Mapivory"
// import Ivorymap from "components/Map"
import imgs_action from "assets/images/visite_banque_mondiale.jpeg"
import Ci from 'assets/images/ci.svg';
const Ousommesnous = ()=>{

    const Images = [
        `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
        "https://img.freepik.com/photos-gratuite/groupe-enfants-africains-apprenant-ensemble_23-2148892526.jpg",
        "https://img.freepik.com/photos-gratuite/petite-fille-souriante-bonheur-studio-portrait-sucette-douce_53876-146342.jpg",
        "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
        "https://img.freepik.com/photos-gratuite/enfants-africains-bras-croises_23-2148852698.jpg",
        "https://img.freepik.com/photos-gratuite/fille-smiley-coup-moyen-ecrit-ordinateur-portable_23-2148789725.jpg",
        "https://img.freepik.com/photos-gratuite/amis-heureux-etreignant-plan-moyen_23-2149479926.jpg",
        "https://img.freepik.com/photos-gratuite/gros-plan-smiley-filles-africaines-dehors_23-2148860429.jpg",
        "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
        `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
        "https://img.freepik.com/photos-gratuite/fille-smiley-coup-moyen-ecrit-ordinateur-portable_23-2148789725.jpg",
        "https://img.freepik.com/photos-gratuite/enfants-africains-bras-croises_23-2148852698.jpg",
    ]

    return(
        <>
          {/* <div className="details-actions-header">
                <div className="row justify-content-center align-items-center mx-auto">
                    <div className="col-md-10 text-center">
                        <h1 className="text-center">
                           Nos Projets
                        </h1>

                    </div>
                </div>
            </div> */}
            <Global_header ref_link='/' img={imgs_action} title="Où sommes nous" first_link="Accueil" second_link="Où sommes nous"/> 
            <div className="projet-header">
                <ActivityHeader 
                    title="Où sommes nous"
                    content="Localissation et zones d'intervention de EICF"
                />
            </div>
            <div className="container">
                <h1 className="text-center mt-5 mb-5">Localisation et zones d'intervention</h1>
                <div className=" row">

                   <div className="col-md-4">
                    <h2>Voir où nous travaillons à travers le pays</h2>
                    <h3>Adresse du bureau national</h3>
                    <div>
                    <li>
                    Bureau Central  (Abidjan)<br/>
                    <span style={{paddingLeft:"22px"}}>Abidjan, angré</span><br/>
                    </li>
                    <li>
                    Bureau  (Bondoukou)<br/>
                    
                    </li>
                    
                    <li>
                    Bureau  (Bouna)<br/>
                    
                    </li>
                    </div>
                    <h3>Envoyez-nous un email</h3>
                    <p> ongeicf@gmail.com/ info@ongeicf.com</p>
                    <h3>Numéros Téléphoniques</h3>
                    <p>(+225) 27 22 53 46 40 / 27 22 01 65 24 / 07 66 91 76 / 03 16 67 84 / 07 07 08 12 85</p>
                   </div>
                   {/* <div className="col-md-8">
                       
                          <Ivorymap/>
                    </div> */}

                    <div className="col-md-8 d-flex justify-content-center" >
                        <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="text-center">Nous intervenons partout à travers le pays.</h2>
                                </div >
                                <div className="col-lg-12  d-flex justify-content-center">
                                <img src={Ci} alt="zones d'intervention" className="img-fluid" style={{height:"500px"}}/>
                                </div>
                        </div>
                    

                    </div>
                    <div className="col-md-6 d-flex justify-content-center ">
                        <div className="region-legend">
                            <div className="region" style={{background:"blue"}}></div>
                            <span>Région des Lagunes</span>
                            <ul>
                                <li>Abidjan</li>
                            </ul>
                        </div>

                        <div className="region-legend">
                            <div className="region" style={{background:"green"}}></div>
                            <span>Région de l’Agneby Tiassa</span>
                            <ul>
                                <li>Agboville</li>
                                <li>Tiassale</li>
                                <li>Sikensi</li>
                                <li>Taabo</li>
                            </ul>
                        </div>

                        <div className="region-legend">
                            <div className="region" style={{background:"red"}}></div>
                            <span>Région du Bélier</span>
                            <ul>
                                <li>Yamoussoukro</li>
                                <li>Toumodi</li>
                                <li>Didievi</li>
                                <li>Djakanou</li>
                                <li>Tiebissou</li>
                            </ul>
                        </div>

                        

                       

                        
                    </div>
                    <div className="col-md-6 d-flex justify-content-center ">
                        <div className="region-legend">
                            <div className="region"  style={{background:"yellow"}}></div>
                            <span>Région du NZI</span>
                            <ul>
                                <li>Dimbokro</li>
                                <li>Bocanda</li>
                                <li>Kouassi-kouassikro</li>
                            </ul>
                        </div>
                        <div className="region-legend">
                            <div className="region"  style={{background:"yellow"}}></div>
                            <span>Région du Moronou</span>
                            <ul>
                                <li>Bongouanou</li>
                            </ul>
                        </div>

                        <div className="region-legend">
                            <div className="region"  style={{background:"yellow"}}></div>
                            <span>Région de l’Iffou</span>
                            <ul>
                                <li>Daoukro</li>
                                <li>Prikro</li>
                                <li>Mbahiakro</li>
                            </ul>
                        </div>
                        
                    </div>
                    <div className="col-md-6 d-flex justify-content-center ">
                        <div className="region-legend">
                            <div className="region" style={{backgroundColor:"cyan"}}></div>
                            <span>Région du Gontougo</span>
                            <ul>
                                <li>Bondoukou</li>
                                <li>Tanda</li>
                                <li>Sandegue</li>
                                <li>Transua</li>
                                <li>Koun-fao</li>
                            </ul>
                        </div>

                        <div className="region-legend">
                            <div className="region"  style={{background:"cyan"}}></div>
                            <span>Région du Bounkani</span>
                            <ul>
                                <li>Bouna</li>
                                <li>Doropo</li>
                                <li>Teheni</li>
                                <li>Nassian</li>
                            </ul>
                        </div>

                        <div className="region-legend">
                            <div className="region"  style={{background:"orange"}}></div>
                            <span>Région de la BAGOUE</span>
                            <ul>
                                <li>Boundiali</li>
                                <li>Kouto</li>
                                <li>Tengrela</li>
                            </ul>
                        </div>

                       
                    </div>
                    <div className="col-md-6 d-flex justify-content-center ">
                        <div className="region-legend">
                            <div className="region"  style={{background:"maroon"}}></div>
                            <span>Région du Tchôlogo</span>
                            <ul>
                                <li>Kong</li>
                            </ul>
                        </div>

                        <div className="region-legend">
                            <div className="region"  style={{background:"orange"}}></div>
                            <span>Région du Bere</span>
                            <ul>
                                <li>Kounahiri</li>
                                <li>Kongasso</li>
                            </ul>
                        </div>

                        <div className="region-legend">
                            <div className="region"  style={{background:"olive"}}></div>
                            <span>Région du Tonpki</span>
                            <ul>
                                <li>Danane</li>
                                <li>Zouan-Gouin</li>
                                <li>Biankouman</li>
                                <li>Man</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Ousommesnous