import ActivityHeader from "components/ActivityHeader";
import AppHeader from "components/AppHeader";
import CardAction from "components/CardAction3";

import "assets/styles/Actions.css";
import img_fondateur from "assets/images/directeur2.jpeg";
import equipe1 from "assets/images/equipe1.jpeg";
import equipe2 from "assets/images/equipe2.jpeg";
import equipe3 from "assets/images/equipe3.jpeg";
import vero from "assets/images/vero.jpg";
import eric from "assets/images/eric.jpg";
import dona from "assets/images/dona.jpg";
import rapport_annuel from "assets/images/rapport_annuel.png";
import rapport_pmndpe from "assets/images/rapport_pmndpe.png";
import rapport_projetswedd from "assets/images/rapport_projetswedd.png";
import rapport1 from "assets/images/rapport1.png";

import rapport_annuel_pdf from "assets/images/rapport_annuel.pdf";
import rapport_pmndpe_pdf from "assets/images/rapport_pmndpe.pdf";
import rapport_projetswedd_pdf from "assets/images/rapport_swedd.pdf";
import rapport1_pdf from "assets/images/rapport1.pdf";

import dev_comm from "assets/images/developpement_comunautaire.jpg";
import entrep from "assets/images/entrepreuneuriat.jpg";
import sante from "assets/images/nutrition.jpg";
import alphab from "assets/images/alphabetisation.jpg";
import action_social from "assets/images/action_sociale.jpg";
import droit_de_lhomme from "assets/images/droit_de_homme.jpg";
import paix_cohesion from "assets/images/paix_et_cohesion sociale.jpg";

import dev_durable from "assets/images/environnement.jpg";
import Global_header from "components/Global_header";
import data_action from "components/data/action";

import img_action from "assets/images/action_sociale.jpg"

const Actions = () => {
  const Images = [
    `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
    "https://img.freepik.com/photos-gratuite/groupe-enfants-africains-apprenant-ensemble_23-2148892526.jpg",
    "https://img.freepik.com/photos-gratuite/petite-fille-souriante-bonheur-studio-portrait-sucette-douce_53876-146342.jpg",
    "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
    "https://img.freepik.com/photos-gratuite/enfants-africains-bras-croises_23-2148852698.jpg",
    "https://img.freepik.com/photos-gratuite/amis-heureux-etreignant-plan-moyen_23-2149479926.jpg",
    "https://img.freepik.com/photos-gratuite/gros-plan-smiley-filles-africaines-dehors_23-2148860429.jpg",
    "https://img.freepik.com/photos-gratuite/adorables-enfants-noirs-africains_23-2149220007.jpg",
    `https://img.freepik.com/photos-gratuite/garcons-africains-smiley-coup-moyen_23-2148860433.jpg`,
  ];

  return (
    <>
      {/* <div className="header2 mb-3 ">
        <center>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"></div>

            <div className="col-lg-6 col-md-3 col-sm-12 col-xs-12 mb-3">
              <center>
                <div className="col-lg-12 col-md-7 mb-3">
                  <center>
                    <h1>NOS ACTIONS  </h1>
                  </center>

                  <center>
                    <div className="title_founder2  ml-8"></div>
                  </center>

                  <center className="mt-2 margin_founder">
                    <p>Accueil > Nos actions </p>
                  </center>

                  <br />
                </div>
              </center>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-4 col-xs-4"></div>
          </div>
        </center>
      </div> */}
      <Global_header ref_link='/' img={img_action} title="Nos actions" first_link="Accueil" second_link="Nos Actions"/>
      <div className="row mt-4">
        <div class="col-lg-12 col-md-12 col-xs-12 col-">
          <center>
            <div className="title_founder3"></div>
          </center>
        </div>
      </div>

      <div className="row mt-4">
        {
          data_action?.map(elt=>(
            <div className="col-lg-4 col-md-4 col-12 col-xs-12 col-sm-12">
            <CardAction
              title={elt.titre}
              img={elt.image}
              text=""
              link={"/actions-details/"+elt.id}
            />
          </div>
          ))
        }
        

       
      </div>
    </>
  );
};

export default Actions;
