
import { Link } from "react-router-dom";
import cardImg from "assets/images/card_img.jpeg"
import { useNavigate } from "react-router-dom";
import "assets/styles/CardAction.css"
import { Button } from "@chakra-ui/react";

const CardAction =  ({text,title,link,img,to})=>{
    const navigate = useNavigate();


    const showActionDetails = ()=>{
        navigate(!!to?to:"#")
    }

    return(
        
        <div 
            className="col-lg-4 col-12 col-xs-12 col-sm-12 col-md-3 p-4  card-action shadow mt-4  rounded-4"
            onClick={showActionDetails}>
            <div>
                <div className="card-action-image-container">
                    <img src={img} alt="ONG EICF" className="img-fluid mx-auto  rounded-top" />
                </div>
                <div className="card-action-body px-3 text-center pt-1">

                    <h6> {title} </h6>
                    <h4 className="fw-bolder pt-2" > {title} </h4>
                    
                    <p className="" > {text} </p>
                   
                    {/*<Link className="card-link" to={!!link?link:"/actions-details"}> Comprendre </Link>*/}
                   
                </div>
               { /*<div className="btn-container w-100 px-3">
                        <Button 
                            style={{color:'var(--color-primary-yellow)'}}
                            colorScheme='var(--color-primary-yellow)' 
                            className="rounded-pill w-100 py-4 "
                            variant='outline'>
                            En savoir plus
                        </Button>
                    
    </div>*/}
            </div>
        </div>
        
    )
}

export default CardAction